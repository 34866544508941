import React, { Component } from 'react';
import { AuthUserContext } from '../Session';
import { withAuthorization } from '../Session';
import './styles.css'
import _ from 'lodash';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { Accordion, AccordionDetails, AccordionSummary, Pagination } from '@mui/material';
import { Launch } from '@mui/icons-material';
import { ExpandMore } from '@mui/icons-material';

class ProjectPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groupsList: [],
      projectName: null,
      sampleDetailContainerHeight: [],
      sampleDetailContainerWidth: [],
      thumbnailDisplayedURL: [],
      sampleDescriptionList: [],
      organisationId: null,
      selectedSampleList: [],
      selectedCohortList: [],
      numberOfPages: [],
      pageDisplayed: [],

    };
  }

  componentDidMount() {
    const projectId = this.props.match.params.projectId
    const uid = this.props.firebase.auth.currentUser.uid
    this.props.firebase.Users().doc(uid).get()
      .then((user) => {
        this.setState({ organisationId: user.data().organisation_id }, 
          () => {
            this.handleFetchGroups(projectId, this.state.organisationId)
          })
      })
    this.props.firebase.Projects().doc(projectId).get()
      .then((project) => {
        document.title = "Projects - " + project.data().name;
        this.setState({ projectName: project.data().name })
      })
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.projectId !== prevProps.match.params.projectId) {
      const projectId = this.props.match.params.projectId
      this.setState({ projectId })
      this.handleFetchGroups(projectId)
      this.props.firebase.Projects().doc(projectId).get()
        .then((project) => {
          document.title = "Projects - " + project.data().name;
          this.setState({ projectName: project.data().name })
        })
    }
  }

  async handleFetchGroups(projectId) {
    try {
      const groupsSnapshot = await this.props.firebase.Groups().where('project_id', '==', projectId).orderBy('last_update').get();
      let selectedSampleList = [];
      let selectedCohortList = [];
      let sampleDetailContainerHeight = [];
      let sampleDetailContainerWidth = [];
      let groupsList = [];
      let pageDisplayed = [];
      groupsSnapshot.forEach(element => {
        selectedSampleList.push([]);
        selectedCohortList.push(false);
        pageDisplayed.push(1);
        sampleDetailContainerHeight.push('0px');
        sampleDetailContainerWidth.push('0px');
        let group = element.data();
        group['id'] = element.id;
        group['last_update'] = group['last_update'].toDate().toString().substring(0, 15);
        group['creation_date'] = group['creation_date'].toDate().toString().substring(0, 15);
        groupsList.push(group);
      });
      this.setState({pageDisplayed, selectedCohortList, sampleDetailContainerHeight, sampleDetailContainerWidth})
      let numberOfPages = [];
      await Promise.all(groupsList.map(async (item, index) => {
        item['samples'] = [];
        const samplesSnapshot = await this.props.firebase.Samples().where('cohort_id', '==', item.id).get();
        numberOfPages.push(Math.ceil(samplesSnapshot.docs.length / 6))
        await Promise.all(samplesSnapshot.docs.map(async (element) => {
          selectedSampleList[index].push(false)
          const thumbnailRef = this.props.firebase.Storage().ref(`${this.state.organisationId}/${element.id}/thumbnail.jpg`);
          let resultURL;
          try {
            resultURL = await thumbnailRef.getDownloadURL();
          } catch (error) {
            resultURL = null;
          }
          let sample = element.data();
          sample['id'] = element.id;
          sample['last_update'] = sample['last_update'].toDate().toString().substring(0, 15);
          sample['creation_date'] = sample['creation_date'].toDate().toString().substring(0, 15);
          sample['thumbnailUrl'] = resultURL;
          item['samples'].push(sample);
        }));
      }));
      this.setState({ groupsList, selectedSampleList, numberOfPages });
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  }
  

  handleCohortSelection = (cohortIndex) => {
    let selectedCohortList = [... this.state.selectedCohortList]
    selectedCohortList[cohortIndex] = !selectedCohortList[cohortIndex]
    this.setState({selectedCohortList})
  }

  handleSampleClick = (cohortIndex, sampleIndex, thumbnailUrl, sampleDescription) => {
    let thumbnailsArray = [... this.state.thumbnailDisplayedURL]
    thumbnailsArray[cohortIndex] = thumbnailUrl

    let sampleDescriptionListUpdated = [... this.state.sampleDescriptionList]
    sampleDescriptionListUpdated[cohortIndex] = sampleDescription

    let sampleDetailContainerHeight = [... this.state.sampleDetailContainerHeight]
    sampleDetailContainerHeight[cohortIndex] = '300px'
    let sampleDetailContainerWidth = [... this.state.sampleDetailContainerWidth]
    sampleDetailContainerWidth[cohortIndex] = '250px'

    this.setState({thumbnailDisplayedURL:thumbnailsArray, sampleDescriptionList:sampleDescriptionListUpdated, sampleDetailContainerHeight, sampleDetailContainerWidth})

    let selectedSampleList = [... this.state.selectedSampleList]
    selectedSampleList[cohortIndex].map((element, index) => {
      selectedSampleList[cohortIndex][index] = false
    })
    selectedSampleList[cohortIndex][sampleIndex] = true
    this.setState({selectedSampleList})
  }

  handleChangePage = (cohortIndex, value) => {
    let pageDisplayed = [... this.state.pageDisplayed]
    pageDisplayed[cohortIndex] = value
    let sampleDescriptionList = [... this.state.sampleDescriptionList]
    sampleDescriptionList[cohortIndex] = ''
    let thumbnailDisplayedURL = [... this.state.thumbnailDisplayedURL]
    thumbnailDisplayedURL[cohortIndex] = ''
    this.setState({pageDisplayed, sampleDescriptionList, thumbnailDisplayedURL})
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div id='project-page'>
            <div id='header-project-page'>
              <h1>{this.state.projectName}</h1>
            </div>
            <div id='header-cohort-list-container'>
                <p className='cohort-name-container' >Cohort name</p>
                <p className='form-creation-date-container'>Creation date</p>
                <p className='sample-number-container'>Sample number</p>
            </div>
            <div id='cohort-list-container'>
              <div id = 'cohort-list'>
                {this.state.groupsList.map((element, cohortIndex) => (
                  <Accordion className='cohort-accordion' key={element.id} disableGutters expanded = {this.state.selectedCohortList[cohortIndex]}>
                    <AccordionSummary 
                      className='cohort-accordion-summary' 
                      expandIcon={<ExpandMore />}
                      onClick = {() => {this.handleCohortSelection(cohortIndex)}}
                      sx = {this.state.selectedCohortList[cohortIndex] ? {backgroundColor: 'rgb(247, 247, 255)', color:'rgb(39, 72, 163)'} : {}}
                    >
                      <p className='cohort-name-container'>{element.label}</p>
                      <p className='cohort-creation-date-container'>{element.creation_date}</p>
                      <p className='sample-number-container'>{element.samples.length}</p>
                    </AccordionSummary>
                    <AccordionDetails className = 'cohort-accordion-details'>
                      <div className='cohort-accordion-details-browser-container' style = {{minHeight:this.state.sampleDetailContainerHeight[cohortIndex]}}>
                        <div className='sample-list-container'>
                          <div className = 'sample-list-header'>
                            <p className = 'sample-list-name-container'>Sample name</p>
                            <p className = 'sample-list-last_update-container'>Last update</p>
                            <p className = 'sample-list-size-container'>Size</p>
                            <p className = 'sample-list-modalities-container'>Modalities</p>
                            <p className = 'sample-list-open-viewer-icon'>Viewer</p>
                          </div>
                          {element.samples.map((sample, sampleIndex) => {
                            const { pageDisplayed } = this.state;
                            const startIndex = (pageDisplayed[cohortIndex] - 1) * 6;
                            const endIndex = pageDisplayed[cohortIndex] * 6 - 1;
                            
                            if (sampleIndex >= startIndex && sampleIndex <= endIndex) {
                              return (
                                <div
                                  key={sample.id}
                                  className='sample-list-element'
                                  onClick={() => {
                                    this.handleSampleClick(cohortIndex, sampleIndex, sample.thumbnailUrl, sample.description);
                                  }}
                                  style={
                                    this.state.selectedSampleList.length !== 0 && this.state.selectedSampleList[cohortIndex][sampleIndex]
                                      ? {
                                          backgroundColor: 'rgb(242, 242, 242)',
                                          cursor: 'default',
                                        }
                                      : {}
                                  }
                                >
                                  <p className='sample-list-name-container'>{sample.name}</p>
                                  <p className='sample-list-last_update-container'>{sample.last_update}</p>
                                  <p className='sample-list-size-container'>{sample.size}</p>
                                  <p className='sample-list-modalities-container'>{sample.modalities.length}</p>
                                  {sample.thumbnailUrl ? (
                                    <p className='sample-list-open-viewer-icon' style={{ height: '20px' }}>
                                      <Link target="_blank" rel="noopener noreferrer" to={ROUTES.FILE_BROWSER + '/' + this.props.match.params.projectId + '/' + sample.thumbnailUrl.split('%2F')[1]}>
                                        <Launch id='open-viewer-icon' />
                                      </Link>
                                    </p>
                                  ) : (
                                    <p className='sample-list-open-viewer-icon' style={{ height: '20px' }}>-</p>
                                  )}
                                </div>
                              );
                            } else {
                              return null; // Don't render the element if it's not within the specified range
                            }
                          })}         

                        </div>
                        <Pagination className = 'accordion-details-pagination' 
                          count={this.state.numberOfPages[cohortIndex]}
                          // page={this.state.pageDisplayed[cohortIndex]}
                          onChange={(event, value) => this.handleChangePage(cohortIndex, value)}
                          size="small" 
                        />
                      </div>
                      <div className='cohort-accordion-details-sample-detail-container' style = {{minWidth:this.state.sampleDetailContainerWidth[cohortIndex], height:this.state.sampleDetailContainerHeight[cohortIndex]}}>
                        <img className = 'cohort-accordion-details-sample-thumbail' src = {this.state.thumbnailDisplayedURL[cohortIndex]} alt = '' />
                        <em>Description:</em>
                        <p> {this.state.sampleDescriptionList[cohortIndex]} </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    )
  }
};


const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(ProjectPage);
