import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import LandingPage from '../Landing';
import FormPage from '../ContactForm';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import SettingsPage from '../Settings';
import ProjectPage from '../ProjectPage';
import SampleDataPage from '../SampleDataPage';
import NewSampleRegistration from '../NewSampleRegistration';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import NavigationApp from '../NavigationApp';
import './styles.css'
import { withFirebase } from '../Firebase';

const PageWithNavigation = ({ component: Component }) => (
  <>
    <NavigationApp />
    <Component />
  </>
);

const App = () => (
    <Router>
        <div id = 'appContent'>
        <Switch>
            <Route exact path={ROUTES.LANDING} component={LandingPage} />
            <Route exact path={ROUTES.CONTACT_FORM} component={FormPage} />
            <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />

            <Route exact path={ROUTES.HOME} render={() => <PageWithNavigation component={HomePage} />} />
            <Route exact path={ROUTES.SETTINGS} render={() => <PageWithNavigation component={SettingsPage} />} />
            <Route exact path={ROUTES.NEW_REGISTRATION} render={() => <PageWithNavigation component={NewSampleRegistration} />} />
            <Route exact path='/projects/:projectId' render={() => <PageWithNavigation component={ProjectPage} />} />
            <Route exact path='/projects/:projectId/:sampleId' component={SampleDataPage} />
          </Switch>
        </div>
    </Router>
);

export default withAuthentication(App);
