import React , { Component } from 'react';
import './styles.css'
import {Switch, Slider} from '@mui/material/';
import Histogram from '../Histogram/histogram.js';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const sliderTheme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        thumb: {
          height: '8px',
          borderRadius: '0px',
          width: '3px',
          boxShadow: 'none', 
          '&:hover': {
            boxShadow: 'none', 
          },
          '&:click': {
            boxShadow: 'none', 
          },
          '&:select': {
            boxShadow: 'none', 
          },
        },
        track: {
          height: '1px',
        },
        rail: {
          height: '1px',
        },
        root: {
          color: 'rgb(220, 220, 220)',
        },
      },
    },
  },
});

const switchTheme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-thumb': {
            color: 'gray', 
          },
          '& .MuiSwitch-track': {
            backgroundColor: 'lightgray', 
          },
          '& .Mui-checked': {
            '& .MuiSwitch-thumb': {
              color: 'white', 
            },
            '& .MuiSwitch-track': {
              backgroundColor: 'lightgray',
            },
          },
        },
      },
    },
  },
});


class ChannelTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widthfirst: this.props.values[0],
      widthlast: this.props.values[1],
      switchState: true,
    };

  }

  handleSwitchStateChange() {
    this.setState({switchState:!this.state.switchState})
    this.props.disableChannel(!this.state.switchState)
  }

  render () {
      return (
        <div>

          <div className="channelTab-container" >
            <div id = 'channel-tab-header'>
              <p>{this.props.modalityName}</p>
              <ThemeProvider theme={switchTheme}>
                <Switch 
                  checked={this.state.switchState}
                  onChange={() => {this.handleSwitchStateChange()}}
                  size="small" 
                />
              </ThemeProvider>
            </div>
            <div id = 'histo-container' >
              <Histogram data={this.props.histogramData} plotColor = {this.props.histoColor} />
              <div id = 'histo-overlay-container'>
                  <div className = 'histo-overlay' style = {{ width:this.props.values[0]*300, borderRight:'1px rgb(150, 150, 150) dashed'}}> </div>
                  <div className = 'histo-overlay' style = {{ width:(1-this.props.values[1])*300, borderLeft:'1px rgb(150, 150, 150) dashed'}}> </div>
              </div>
            </div>

            <div className="slider-containter">
              <ThemeProvider theme={sliderTheme}>
                <Slider
                  size="small"
                  value={this.props.values}
                  onChange={(e, newValue) => {this.props.updateChannelValue(e, newValue); this.setState({widthfirst: newValue[0]*300, widthlast: (1-newValue[1])*300})}}
                  valueLabelDisplay="off"
                  onChangeCommitted={ (e, newValue) => {this.props.updateLUTValueInDB(e, newValue)}}
                  step={0.01}
                  min={0}
                  max={1}
                  style = {{boxShadow: "none"}}
                />
              </ThemeProvider>
            </div>
          </div>
        </div>

        
      )
    }
  };


export default ChannelTab;
