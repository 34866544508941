import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import { AuthUserContext } from '../../../Session';
import { withAuthorization } from '../../../Session';
import './styles.css'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { duration } from 'moment';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function Histogram({ data, plotColor}) {

    const [chartData, setChartData] = useState(0);

    function updateData() {
        //var bins = data[0].split(":")[1];
        var bins = data.length;
        var dataPointsY = [];
        var dataPointsX = [];
        if (typeof bins !== "undefined") {
            dataPointsX.push(0);
            dataPointsY.push(0);
            for (let i = 1; i < bins; i++) {
                dataPointsX.push(data[i].split(";")[0]);                
                dataPointsY.push(data[i].split(";")[1]);
            }

            setChartData({
                needUpdate: true,
                data: {
                    labels: dataPointsX,
                    datasets: [
                        {
                            label: 'Histogram',
                            data: dataPointsY,
                            borderColor: plotColor,
                            borderWidth: 2,
                            backgroundColor: 'rgba(255, 50, 132, 0)',
                        },
                    ],
                },
                options:
                {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                            display: false,
                        },
                        title: {
                            display: false,
                            text: 'Histogram',
                        },
  
                   
                    },
                    scales: {
                        y: {
                          display: false,
                          grid : {display: true},
                        },
                        x: {
                            display: false,
                          },
                    },
                    animation : {
                        duration: 0,
                    },
                    elements: {
                        point:{
                            radius: 0
                    }},
                },
            });
        }
    }

    function handleChange(e) {
        setChartData({
            ...chartData,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        if (typeof data !== "undefined") {
            updateData();
        }
    }, [data]);

    if (chartData) {
        return (
                <Line options={chartData.options} data={chartData.data} height={'100px'}/>
        );
    }
    else {
        return (
            <div/>
        );
    }
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(Histogram);
