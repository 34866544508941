/*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~
/* openSeadragonGL - Set Shaders in OpenSeaDragon with viaWebGL
/*
/* CHANGES MADE BY
/* Jiří Horák, 2021
*/
import { AuthUserContext } from '../Session';
import PasswordChangeForm from '../PasswordChange';
import { withAuthorization } from '../Session';
import OpenSeadragon from "openseadragon";
import ViaWebGL from './viaWebGL.js';
import { forEach } from 'lodash';

class openSeadragonGL {
    constructor(openSD, webGL, numChannel) {

        /* OpenSeaDragon API calls
        ~*~*~*~*~*~*~*~*~*~*~*~*/
        this.openSD = openSD;
        this.viaGL = webGL;
        this.upToDateTStamp = Date.now();

        this.mainChannel = numChannel - 1;
        this.maxChannel = numChannel;

        this.interface = {
            //'tile-loaded': canMixShaders ? function (e) { // Can draw tile source with shader and switch to using no shader and vice versa
            'tile-loaded': function (e) { // Can draw tile source with shader and switch to using no shader and vice versa
                if (!e.data) return;
                // Set correct dimensions (problematic border tiles)

                if (e.tiledImage.source.channel == this.mainChannel) {
                    this.viaGL.setDimensions(e.tile.sourceBounds.width, e.tile.sourceBounds.width);
                    if (this.maxChannel > 1) {
                        var other = e.eventSource.world.getItemAt((this.mainChannel + 1) % 2 % 2);

                        var width = e.eventSource.world.width;
                        var height = e.eventSource.world.height;
                        console.log(width, height)
                        var level = e.tile.level;
                        var x = e.tile.x;
                        var y = e.tile.y;
                        var numTiles = other.source.getNumTiles(level);
                        var currentTime = Date.now();

                        //var tile_url = other.source.getTileUrl(level, x, y);
                        var tile = other._getTile(x, y, level, currentTime, numTiles, width, height);
                        if (tile.origData != null) {
                            this.viaGL.toTexture2(tile.origData, e);
                        }
                        else {
                            if (!tile.loaded) {
                                if (tile.context2D) {
                                    other._setTileLoaded(tile);
                                } else {
                                    var imageRecord = other._tileCache.getImageRecord(tile.cacheKey);
                                    if (imageRecord) {
                                        other._setTileLoaded(tile, imageRecord.getData());
                                    }
                                }
                            }
                        }
                    }

                    var output = this.viaGL.toCanvas(e.data, e);

                    if (output !== null) {
                        var canvas = document.createElement('canvas')
                        canvas.width = e.tile.sourceBounds.width;
                        canvas.height = e.tile.sourceBounds.height;
                        var renderedContext = canvas.getContext('2d');

                        renderedContext.drawImage(output, 0, 0);
                        // Save the result as tile context so it will be used from now on
                        e.tile.context2D = renderedContext;
                    }
                }
                // Else let openseadragon handle the event

                // Save the original image for furture use, note when the tile was last rendered and re-draw if too old
                e.tile.origData = e.data;
                e.tile.webglRefresh = Date.now();

            }
        };

        this.defaults = {
            'tile-loaded': function (callback, e) {
                callback(e);
            }
        };

    }

    /**
     * Force a full redraw.
     * @param {OSD} osd - openSeadragon instance containing world instance
     */
    forceRedraw(osd) {
        //redraw was called we probably changed uniform data, reset
        this.viaGL.forceSwitchShader(0);

        var world = osd.world;
        // Raise tstamp to force redraw
        this.upToDateTStamp = Date.now();
        world._needsDraw = true;
    }

    /**
     * Redraw the scene using cached images.
     * @param {OSD} osd - openSeadragon instance containing world instance
     */
    redraw(osd) {
        //redraw was called we probably changed uniform data, reset
        this.viaGL.forceSwitchShader(0);

        var world = osd.world;
        var imageTile = world.getItemAt(0);
        // Raise tstamp to force redraw
        this.upToDateTStamp = Date.now();
        imageTile._drawer.context.clearRect(0, 0, imageTile._drawer.context.canvas.width, imageTile._drawer.context.canvas.height);
        imageTile.draw();
    }


    //////////////////////////////////////////////////////////////////////////////
    ///////////// YOU PROBABLY DON'T WANT TO READ/CHANGE FUNCTIONS BELOW
    //////////////////////////////////////////////////////////////////////////////

    // Map to viaWebGL and openSeadragon
    init() {
        // Instead of choosing loaded/drawing, always use loaded
        this.addHandler('tile-loaded');

        var open = this.merger.bind(this);
        this.openSD.addHandler('open', open);
        return this;
    }

    // User adds events
    addHandler(key, custom) {
        if (key in this.defaults) {
            this[key] = this.defaults[key];
        }
        if (typeof custom == 'function') {
            this[key] = custom;
        }
    }

    // Merge with viaGL
    merger(e) {
        // Take GL height and width from OpenSeaDragon
        this.width = this.openSD.source.getTileWidth();
        this.height = this.openSD.source.getTileHeight();
        // Add all viaWebGL properties
        for (var key of this.and(this.viaGL)) {
            this.viaGL[key] = this[key];
        }
        this.viaGL.init().then(this.adder.bind(this));
    }
    // Add all seadragon properties
    adder(e) {
        for (var key of this.and(this.defaults)) {
            var handler = this[key].bind(this);
            var interface_tmp = this.interface[key].bind(this);
            // Add all openSeadragon event handlers
            this.openSD.addHandler(key, function (e) {
                handler.call(this, interface_tmp, e);
            });
        }

        //hardcoded handler for re-drawing elements from cache
        var cacheHandler = function (e) {
            //if (e.tile.webglRefresh <= this.upToDateTStamp)
            {
                e.tile.webglRefresh = this.upToDateTStamp + 1;
                if (e.tiledImage.source.channel == this.mainChannel) {
                    if (this.maxChannel > 1) {
                        var width = e.eventSource.world.width;
                        var height = e.eventSource.world.height;
                        var level = e.tile.level;
                        var x = e.tile.x;
                        var y = e.tile.y;

                        //var tile_url = other.source.getTileUrl(level, x, y);

                        var other = e.eventSource.world.getItemAt((this.mainChannel + 1) % 2);
                        var numTiles = other.source.getNumTiles(level);
                        var currentTime = Date.now();

                        var tile = other._getTile(x, y, level, currentTime, numTiles, width, height);
                        if (tile.origData != null) {
                            this.viaGL.toTexture2(tile.origData, e);
                        }
                        else {
                            if (!tile.loaded) {
                                if (tile.context2D) {
                                    other._setTileLoaded(tile);
                                } else {
                                    var imageRecord = other._tileCache.getImageRecord(tile.cacheKey);
                                    if (imageRecord) {
                                        other._setTileLoaded(tile, imageRecord.getData());
                                    }
                                }
                            }
                        }
                    }

                    // Render a webGL canvas to an input canvas using cached version
                    var output = this.viaGL.toCanvas(e.tile.origData, e);
                    e.rendered.drawImage(output == null ? e.tile.origData : output, 0, 0, e.tile.sourceBounds.width, e.tile.sourceBounds.height);
                }
            }
        }.bind(this);
        this.openSD.addHandler('tile-drawing', cacheHandler);
    }

    // Joint keys
    and(obj) {
        return Object.keys(obj).filter(Object.hasOwnProperty, this);
    }

}

const authCondition = authUser => !!authUser;

//export default withAuthorization(authCondition)(openSeadragonGL);
export default openSeadragonGL;