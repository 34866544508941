import React from 'react';
import { Icon } from '@iconify/react';
import offIcon from '@iconify/icons-el/off';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {Logout} from '@mui/icons-material';

import { withFirebase } from '../Firebase';
import './styles.css'

const SignOutButton = ({ firebase }) => (
    <Link className = 'link-navigation-app-bar' to={ROUTES.SETTINGS} onClick={firebase.doSignOut}>
      <Logout className = 'navigation-app-icon'  />
      <p>Logout</p>
    </Link>
);

export default withFirebase(SignOutButton);
