import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withAuthorization } from '../Session';

import NavigationApp from '../NavigationApp';
import './styles.css'
import { Icon } from '@iconify/react';
import moment from 'moment'
import _, { sampleSize } from 'lodash';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import openSeadragonGL from './openSeadragonGL.js';
import OpenSeaDragon from "openseadragon";

import ChannelTab from './components/ChannelTab'
import { folder } from 'jszip';
import ViaWebGL from './viaWebGL';

import {CircularProgress} from '@mui/material';

class SampleDataPage extends Component {
    constructor(props) {
        super(props);

        const sampleId = this.props.match.params.sampleId;
        const groupId = this.props.match.params.groupId;
        const projectId = this.props.match.params.projectId;
        const uid = this.props.firebase.auth.currentUser.uid;

        this.state = {
    
            organisationId: "",
            tokens: {},
            viewer: "",
            viewerOpacity: 1,
            loading: true,
            channels: [],
            uid: uid,
            sampleId: sampleId,
            groupId: groupId,
            projectId: projectId,
            brightness: [],
            lut_from: [],
            lut_to: [],
            mean: [],
            min: [],
            max: [],
            histograms: [],
            LUT: [[0, 1], [0, 1], [0, 1]],
            modalitiesName: {},
            histoColor : ['red', 'green', 'blue'],
        };
    }

    componentDidMount() {
        this.props.firebase.Samples().doc(this.state.sampleId).get()
            .then((sample) => {
                document.title = 'Sample - ' + sample.data().name
                let modalityList = []
                for (let i = 0; i < sample.data().modalities.length; i++) {
                    if (sample.data().modalities[i] !== 'GvWA2PST2Zf5kZbFLOYH') {
                        modalityList.push(sample.data().modalities[i]);
                    }
                }
                this.setState({
                    organisationId: sample.data().organisation_id,
                    channels: modalityList,
                }, 
                () => {
                    let modalitiesName = {...this.state.modalitiesName}
                    sample.data().modalities.map((modalityId) => {
                        if(modalityId != 'GvWA2PST2Zf5kZbFLOYH'){
                            this.props.firebase.Modalities().doc(modalityId).get()
                            .then((modality) => {
                                console.log(modality.data().name)
                                
                                modalitiesName[modalityId] = modality.data().name
                                this.setState({modalitiesName})
                            })
                        }
                    })
                    this.HandleInitialization(sample)
                })
            })
    }

    async HandleFetchAllImageTokens (folderRef, nextPageToken, tokens) {

        var fetchAllTokens = async (folderRef, nextPageToken, tokens) => {
                const nextPage = await folderRef.list({maxResults: 100, pageToken: nextPageToken });
                for await (const result of nextPage.items.map(async imageRef => {return [imageRef.name, await imageRef.getDownloadURL()]})) {
                    tokens[result[0]] = result[1]
                }
                this.setState({tokens}, () => {
                    if(nextPage.nextPageToken) {
                        fetchAllTokens(folderRef, nextPage.nextPageToken, tokens)
                    } 
                })
        }
        await fetchAllTokens(folderRef, nextPageToken, tokens)
    }

    async HandleInitialization(sample) {
        var tokens = {};
        const baseUrl = 'https://firebasestorage.googleapis.com/v0/b/hyprview-app.appspot.com/o/' + this.state.organisationId + '/' + this.state.sampleId

        const folderRef = this.props.firebase.Storage().ref(this.state.organisationId + '/' + this.state.sampleId + '/multi');
        const firstTilesBundle = await folderRef.list({maxResults: 50});

        for await (const result of firstTilesBundle.items.map(async imageRef => {return [imageRef.name, await imageRef.getDownloadURL()]})) {
            tokens[result[0]] = result[1]
        }
        console.log(sample.data().height)
        if (firstTilesBundle.nextPageToken) {
            this.HandleFetchAllImageTokens(folderRef, firstTilesBundle.nextPageToken, tokens)
        }

        this.setState({tokens:tokens}, async () => {
            var openSD = OpenSeaDragon({
                id: "openSeaDragon",
                prefixUrl: "openseadragon-images/",
                animationTime: 0.5,
                blendTime: 0.5,
                constrainDuringPan: true,
                maxZoomPixelRatio: 1,
                minZoomLevel: 0,
                visibilityRatio: 1,
                zoomPerScroll: 1.2,
                navigatorPosition: "TOP_LEFT",
                showNavigator: true,
                zoomInButton: "zoom-in",
                zoomOutButton: "zoom-out",
                homeButton: "home",
                fullPageButton: "full-page",
                nextButton: "next",
                previousButton: "previous",
                tileSources: [
                    {
                        //OpenSeaDragon need at least one tile source to work.
                        tileSource: {
                            height: sample.data().height,
                            width: sample.data().width,
                            tileSize: 1000,
                            minLevel: 1,
                            maxLevel: sample.data().max_level,
                            getTileUrl:  (level, x, y) => {
                                console.log('lolo', (level) + "_" + x + "_" + y + '.jpg')
                                console.log('here', this.state.tokens[(level) + "_" + x + "_" + y + '.jpg'])
                                return this.state.tokens[(level) + "_" + x + "_" + y + '.jpg']
                            },
                            ajaxWithCredentials: true,
                            crossOriginPolicy: 'Anonymous',
                            channel: 0,
                        },
                        opacity: 1,
                    },
                ],
            });
            var webGL = new ViaWebGL();
            var seaGL = new openSeadragonGL(openSD, webGL, 1 );
            seaGL.init();

            var brightness = [];
            var mean = [];
            var min = [];
            var max = [];
            var histograms = [];
    
            // For each channels: initialize the UI components related variables using last know values.
            for (var i = 0; i < this.state.channels.length; ++i) {
                
                var channelInfo = await this.props.firebase.Samples().doc(this.state.sampleId).collection("Acquired_Modalities").doc(this.state.channels[i]).get()
                .then((temp) => {
                    return temp.data();
                });

                brightness.push(channelInfo.brightness);
                mean.push(channelInfo.mean);
                min.push(channelInfo.min);
                max.push(channelInfo.max);

                webGL.lutMin[i] = channelInfo.LUT[0];
                webGL.lutMax[i] = channelInfo.LUT[1];

                let LUT = [...this.state.LUT]
                LUT[i] = channelInfo.LUT
                this.setState({LUT})
                webGL.scale[i] = 1;
                
                var histogram_path = await this.props.firebase.Storage().ref(this.state.organisationId + '/' + this.state.sampleId + '/' + this.state.channels[i] + "/histogram.txt").getDownloadURL();
                var histo_values = await fetch(histogram_path).then((r) => { return r.text() }).then((r) => {return r.split("\n")});
                histograms.push(histo_values);
            }
    
            // // Add the other this.state.channels to OpenSD. 
            // for (var i = 0; i < this.state.channels.length / 3; ++i) {

            //     if (i == 0)
            //         continue;
            //     openSD.addTiledImage(
            //         {
            //             tileSource: {
            //                 height: this.state.height,
            //                 width: this.state.width,
            //                 tileSize: 1000,
            //                 minLevel: 1,
            //                 maxLevel: this.state.maxLevel,
            //                 getTileUrl: function (level, x, y) {
            //                     const imageName = channelPathData[this.channel] + "%2F" + (level) + "_" + x + "_" + y + '.jpg';
            //                     if (tokens[imageName] == null) {
            //                         getImageToken(channelList, this.channel, x, y, level).then((token) => tokens[imageName] = token);
            //                     }
            //                     return baseUrl + imageName + '?alt=media&token=' + tokens[imageName];
            //                 },
            //                 ajaxWithCredentials: true,
            //                 crossOriginPolicy: 'Anonymous',
            //                 channel: i,
            //             },
            //             opacity: 1,
            //         }
            //     )
            // }

            this.setState({
                viewer: openSD,
                glContext: webGL,
                viewerGL: seaGL,
                brightness: brightness,
     
                mean: mean,
                min: min,
                max: max,
                histograms: histograms,
            }, () => {
                this.state.viewerGL.redraw(this.state.viewer)
                this.setState({viewerOpacity:1, loading:false})
            });
        })
    }


    disableChannel(i, state) {
        var glContext = {...this.state.glContext}
        glContext.colors[i] = state
        this.setState({glContext}, () => {
            this.state.viewerGL.redraw(this.state.viewer)
        })
    }

    updateLUTValueInDB(i, values) {
        this.props.firebase.Samples().doc(this.state.sampleId).collection("Acquired_Modalities").doc(this.state.channels[i]).update('LUT', values);
    }

    updateChannelValue(i, values) {
        let LUT = {...this.state.LUT}
        LUT[i] = values
        var glContext = {...this.state.glContext}
        glContext.lutMin[i] = values[0]
        glContext.lutMax[i] = values[1]
        this.setState({glContext,LUT}, () => {
            this.state.viewerGL.redraw(this.state.viewer)
        })
    }

    render() {


        return (
            <div id='sample-data-page'>
                {this.state.loading &&
                <div className="loader-container">
                    <CircularProgress/>
                </div>
                }
                <div id='sample-data-container' >
                    <div id='map-container' style = {{ backgroundColor:'black'}}>
                        <div style = {{opacity:this.state.viewerOpacity}} id="openSeaDragon" />
                    </div>
                </div>

                <div id="tabUI">
                    {this.state.channels.map((channel, i) => {
                        return (
                            <ChannelTab 
                                values = {this.state.LUT[i]}
                                modalityName = {this.state.modalitiesName[channel]}
                                histoColor = {this.state.histoColor[i]}
                                updateChannelValue = {(e, values) => {this.updateChannelValue(i, values)}} 
                                updateLUTValueInDB = {(e, values) => {this.updateLUTValueInDB(i, values)}}
                                disableChannel = {(state) => this.disableChannel(i, state)}
                                histogramData = {this.state.histograms[i]}
                            />
                        )
                    })
                    }
                </div>
            </div>
        )
    }
};


const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(SampleDataPage);
