import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { MenuItem, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { styled } from '@mui/styles';
import './styles.css'
import { AuthUserContext } from '../../Session';
import { Select } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';


export default function NewFormPopup(props) {

    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [selectedProject, setSelectedProject] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [isInError, setIsInError] = React.useState(false);

    const handleClickOpen = (event) => {
        setOpen(true)
        setSelectedProject('')
    };

    const handleClose = () => { setOpen(false) };

    const handleChange = (event) => {
        setSelectedProject(event.target.value);
        setIsInError(false)
        setErrorMessage('')
    };

    const handleNewFormCreation = () => {
        if (selectedProject === '') {
            setIsInError(true)
            setErrorMessage('This field is required')
        } else {
            props.handleNewFormCreation(selectedProject)
            setOpen(false)
        }
    };

    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <div id='dialog-new-form-trigger-container' >
                    <div id='trigger-new-form-dialog-div' onClick={(event) => handleClickOpen(event)}>
                        <AddCircleOutline style = {{strokeWidth:'1px', stroke: 'white'}} />
                        <p> Create a new form</p>
                    </div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        scroll={scroll}
                        id="form-dialog"
                        fullWidth={false}
                        PaperProps={{
                            sx: {
                                position: 'relative',
                                overflowY: 'scroll',
                                marginLeft: '300px',
                                width: '400px',
                                maxWidth: '400px',
                                borderRadius: '3px',
                                height: '180px',
                            }
                        }}
                    >
                        <DialogContent>
                            <div id='new-form-dialog-content'>
                                <FormControl size='small' sx={{ width: '220px' }}>
                                    {selectedProject === '' ?
                                        <InputLabel
                                            id="demo-simple-select-standard-label"
                                            shrink={false}
                                            sx={{
                                                width: '150px',
                                                fontSize: '14px',
                                            }}
                                        >
                                            Select a project...
                                        </InputLabel>
                                        : null}
                                    <CustomSelect
                                        labelId="select-project-label"
                                        id="select-project-control"
                                        value={selectedProject}
                                        onChange={handleChange}
                                        sx={{
                                            fontSize: '14px',
                                            borderRadius: '2px',

                                        }}
                                        error={isInError}

                                    >
                                        {Object.keys(props.projectList).map((id) => (
                                            <MenuItem sx={{ fontSize: '14px' }} value={props.projectList[id]} key={id}>{id}</MenuItem>
                                        ))}
                                    </CustomSelect>
                                    <FormHelperText sx={{ color: 'red' }}>{errorMessage}</FormHelperText>
                                </FormControl>


                                <div id='form-dialog-title-close-button'>
                                    <button className='action-button' style={{ width: '150px', height: '36px' }} onClick={() => { handleNewFormCreation() }}>
                                        CREATE A NEW FORM
                                    </button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            )}
        </AuthUserContext.Consumer>
    );
}

const CustomSelect = styled(Select)(({ theme }) => ({
    '&.MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#aaa', // Set the default border color here
        },
        '&:hover fieldset': {
            borderColor: 'black', // Set the border color on hover
        },
        '&.Mui-focused fieldset': {
            border: '1px solid rgb(45, 86, 201)', // Set the border color when focused
        },
    },
}));