import React , { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withAuthorization } from '../Session';
import moment from 'moment'
import './styles.css'
import {Skeleton, Button} from '@mui/material';
import ScrollDialog from './formDialogPopup';
import NewFormPopup from './newFormPopup';
import _ from 'lodash';


class NewSampleRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading : true,
      projectsList : {},
      ordered_formsList : [],
      defaultSelectedOption: '',
      selectedForm : '',
      isFormSelected : false,
      formTitleError : false,
      formTitleErrorMessage : '',
      isFormTitleModified : false,
      newCohortName : '',
      newSampleName : [],
      newSampleDescription : [],
      newCohortEmptyFieldMessageToggle : false,
      newSampleEmptyFieldMessageToggle : 'hidden',  
      user: {},
    };
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
    this.fetchExistingForms = this.fetchExistingForms.bind(this)
  }

  componentDidMount () {
    document.title = 'Sample registration'
    const uid = this.props.firebase.auth.currentUser.uid
    this.props.firebase.Users().doc(uid).get()
    .then((user) => {
      this.setState({user : user.data()})
    })
    this.fetchExistingForms().then(() => {this.setState({loading:false})})
    this.handleFetchProjects(uid)
  }

  handleFetchProjects(uid) {
    this.props.firebase.Projects().where('owner_id', '==', uid).orderBy('last_update').get()
    .then((projects) => {
      var projectsList = {}
      projects.forEach(element => {
        projectsList[element.data().name] = element.id
      });
      this.setState({projectsList})
    })
  };

  formConstructor (projectId) {
    let form = {
      name : 'Test form1',
      creation_date : moment().toDate(),
      is_validated : false,
      is_submitted : false,
      project_id : projectId,
      request_list : {}
    }
    return form
  }


  fetchExistingForms () {
    var fetchPromise = new Promise ((resolve, reject) => {
      this.props.firebase.NewSampleRegistrationForm().get()
      .then((list) => {
        let formsList = {}
        list.forEach((element) => {
          let elementData = element.data()
          console.log(element.id)
          elementData['id'] = element.id
          formsList[element.id] = elementData
        });
        let ordered_formsList = _.orderBy(formsList, ['creation_date'], ['desc']);
  
        let promises = [];

        for (let i = 0; i < ordered_formsList.length; i++) {
          let promise = this.props.firebase.Projects().doc(ordered_formsList[i].project_id).get()
            .then((project) => {
              ordered_formsList[i].project_name = project.data().name;
            });
          promises.push(promise);
        }
  
        Promise.all(promises)
          .then(() => {
            this.setState({ ordered_formsList }, () => {
              resolve('Forms successfully fetched and parsed');
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    })
    return fetchPromise
  }
 

  createNewForm (projectId) {
      let new_form = this.formConstructor(projectId)
      new_form.name = 'New form - ' + moment().format('YYYY-MM-DD HH:mm:ss')
      this.props.firebase.NewSampleRegistrationForm().add(new_form)
      .then(async () => {
        this.fetchExistingForms()
      })
  }
  

  handleFormSubmission (formId) {
    console.log('Form submission')
    console.log(formId)
    let form = this.state.ordered_formsList.find((element) => element.id === formId)
    console.log(form)
    form.is_submitted = true
    this.props.firebase.NewSampleRegistrationForm().doc(formId).set(form)
    .then(async () => {
      this.fetchExistingForms()
      .then(() => {console.log('Form successfully submitted')})
    })
  }

  render () {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div id = 'form-page'>
            <div id = 'new-sample-form-submission-container' >
              <div id = 'new-sample-form-submission-header'>
                <h1>Sample registration form</h1>
              </div>
              <div id = 'header-form-list-container'>
                <li id = 'form-list-header' key='default'>
                    <p className = 'form-name-container' >Name</p>
                    <p className = 'project-name-container' >Project</p>
                    <p className = 'form-creation-date-container'>Creation date</p>
                    <p className = 'form-status-container'>Status</p>
                    <p style = {{width:'100px', textAlign:'center'}}>Submission</p>
                </li>
              </div>

              <ul id = 'form-list'>
                <li className = 'form-list-element' style = {{}}> 
                  <NewFormPopup projectList = {this.state.projectsList} handleNewFormCreation = {this.createNewForm.bind(this)}/>
                </li>
                {this.state.ordered_formsList.map((element) => (
                  <li className = 'form-list-element' key={element.id}>
                    <ScrollDialog 
                      item = {element} 
                      firebase = {this.props.firebase} 
                      handleFormSubmission = {this.handleFormSubmission}
                      fetchExistingForms = {this.fetchExistingForms}
                      projectsList = {this.state.projectsList}
                      uid = {authUser.uid}
                      user = {this.state.user}
                    />
                  </li>
                )
                )}
              </ul>
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    )
  }
};


const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(NewSampleRegistration);
