import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import { styled } from '@mui/styles';
import './styles.css'

import { AuthUserContext } from '../../Session';
import { withAuthorization } from '../../Session';
import { Select, FormControl, MenuItem, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';
import { AddCircleOutline, KeyboardArrowLeft } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Delete, Send, HourglassEmpty, Done, Edit, Save, CheckCircle, Sync, Cancel } from '@mui/icons-material';
import _, { set } from 'lodash';


export default function ScrollDialog(props) {

    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [isFormSaved, setIsFormSaved] = React.useState(true);
    const [formTitle, setFormTitle] = React.useState(props.item.name);
    const [formError, setFormError] = React.useState(false);
    const [formTitleError, setFormTitleError] = React.useState(false);
    const [formTitleErrorMessage, setFormTitleErrorMessage] = React.useState('');
    const [formContent, setformContent] = React.useState(props.item['request_list']);
    const [formContentError, setFormContentError] = React.useState(() => {
        let contentErrors = {}
        Object.keys(props.item['request_list']).map((cohordId) => {
            contentErrors[cohordId] = { isInError: false, errorMessage: '', isDuplicatedSamples: false }
            Object.keys(props.item['request_list'][cohordId].samples).map((sampleId) => {
                contentErrors[cohordId + sampleId] = { isInError: false, errorMessage: '' }
            })
        })
        return contentErrors
    });
    const [newGroup, setNewGroup] = React.useState('');
    const [cohortList, setCohortList] = React.useState({});
    const [selectNewCohortErrorMessage, setSelectNewCohortErrorMessage] = React.useState('');

    React.useEffect(() => {
        const checkFormIcon = document.getElementById('check-form-icon');
        if (isFormSaved && checkFormIcon) {
            checkFormIcon.classList.add('bump');
            const removeBumpClass = () => {
                checkFormIcon.classList.remove('bump');
            };
            checkFormIcon.addEventListener('animationend', removeBumpClass);
            return () => {
                checkFormIcon.removeEventListener('animationend', removeBumpClass);
            };
        }
    }, [isFormSaved]);


    React.useEffect(() => {
        if (props.item['request_list']) {
            var sortedContent = {}
            _.orderBy(props.item['request_list'], ['creation_date'], ['asc']).map((cohort) => {
                sortedContent[cohort.id] = { ...cohort }
            })
            Object.keys(sortedContent).map((key) => {
                let sortedSamples = _.orderBy(sortedContent[key].samples, ['creation_date'], ['asc']);
                sortedContent[key] = { ...sortedContent[key]}
                sortedContent[key].samples = {}
                sortedSamples.map((sample) => {
                    sortedContent[key].samples[sample.id] = sample
                })
                setformContent(sortedContent)
            });
        }
    } , []);

    React.useEffect(() => {
        props.firebase.Groups().where('project_id', '==', props.item.project_id).orderBy('creation_date', 'desc').get()
            .then((cohort) => {
                var cohortList = {}
                cohort.forEach(element => {
                    if (!Object.keys(formContent).includes(element.id)) {
                        console.log(element.id, element.data())
                        cohortList[element.data().label] = element.id
                    }
                });
                setCohortList(cohortList)
            })
    }
        , []);

    const handleClickOpen = (event) => {
        if (event.target.closest('.form-state-container')) {
            return;
        }
        setOpen(true)
    };

    const handleClose = () => { if (!formError) { setOpen(false) } };



    const onChangeFormName = event => {
        setFormTitle(event.target.value)
        setFormTitleError(false)
        setFormTitleErrorMessage('')
        setIsFormSaved(false)
    };

    const onChangeCohortName = (event, cohortId) => {
        let updatedFormContent = { ...formContent }
        updatedFormContent[cohortId].label = event.target.value
        setformContent(updatedFormContent)
        setIsFormSaved(false)

        let updatedFormContentError = { ...formContentError }
        updatedFormContentError[cohortId].isInError = false
        updatedFormContentError[cohortId].errorMessage = ''
        setFormContentError(updatedFormContentError)
    };

    const onChangeSampleName = (event, cohortId, sampleId) => {
        let updatedFormContent = { ...formContent }
        updatedFormContent[cohortId].samples[sampleId].label = event.target.value
        setformContent(updatedFormContent)
        setIsFormSaved(false)

        let updatedFormContentError = { ...formContentError }
        updatedFormContentError[cohortId + sampleId].isInError = false
        updatedFormContentError[cohortId + sampleId].errorMessage = ''
        setFormContentError(updatedFormContentError)
    };

    const onChangeSampleDescription = (event, cohortId, sampleId) => {
        let updatedFormContent = { ...formContent }
        updatedFormContent[cohortId].samples[sampleId].description = event.target.value
        setformContent(updatedFormContent)
        setIsFormSaved(false)
    };

    const handleFormTitleUpdate = () => {
        let formList = []
        props.firebase.NewSampleRegistrationForm().orderBy('creation_date', 'desc').get()
            .then((list) => {
                list.forEach((element) => {
                    if (element.id != props.item.id) {
                        formList.push(element.data().name)
                    }
                });
                if (!formList.includes(formTitle)) {
                    props.item.name = formTitle
                    props.firebase.NewSampleRegistrationForm().doc(props.item.id).set(props.item)
                        .then(() => { setIsFormSaved(true) })
                    setFormTitleError(false)
                    setFormTitleErrorMessage('')
                } else {
                    setFormTitleError(true)
                    setFormTitleErrorMessage('Form name already exists')
                }
            })
    };



    const handleFormContentUpdate = (updatingCohortId, updatingSampleId) => {
        let canBeUpdated = true
        let updatedFormContent = { ...formContent }

        if (updatingSampleId === null) {
            // check if cohort name already exists
            Object.keys(updatedFormContent).map((cohordId) => {
                if (cohordId !== updatingCohortId) {
                    if (updatedFormContent[cohordId].label == updatedFormContent[updatingCohortId].label) {
                        let updatedFormContentError = { ...formContentError }
                        updatedFormContentError[updatingCohortId].isInError = true
                        updatedFormContentError[updatingCohortId].errorMessage = 'Cohort name already exists'
                        setFormContentError(updatedFormContentError)
                        canBeUpdated = false
                    }
                }
            })
            if (canBeUpdated) {
                props.item['request_list'] = updatedFormContent
                props.firebase.NewSampleRegistrationForm().doc(props.item.id).set(props.item)
                    .then(() => { setIsFormSaved(true) })
            }
        } else {
            // check if sample name already exists
            if (updatingSampleId !== null) {
                Object.keys(updatedFormContent[updatingCohortId].samples).map((sampleId) => {
                    if (sampleId !== updatingSampleId) {
                        if (updatedFormContent[updatingCohortId].samples[sampleId].label == updatedFormContent[updatingCohortId].samples[updatingSampleId].label) {
                            let updatedFormContentError = { ...formContentError }
                            updatedFormContentError[updatingCohortId + updatingSampleId].isInError = true
                            updatedFormContentError[updatingCohortId + updatingSampleId].errorMessage = 'Sample name already exists'
                            setFormContentError(updatedFormContentError)
                            canBeUpdated = false
                        }
                    }
                })
                if (canBeUpdated) {
                    props.item['request_list'] = updatedFormContent
                    props.firebase.NewSampleRegistrationForm().doc(props.item.id).set(props.item)
                        .then(() => { setIsFormSaved(true) })
                }
            }
        }
    }


    const handleNewCohortInsertion = (event) => {
        setSelectNewCohortErrorMessage('')
        var updatedFormContent = { ...formContent }
        var isNewCohortAlreadyInTheForm = false
        let updatedFormContentError = { ...formContentError }

        if (event.target.value === 'new-cohort') {
            Object.keys(updatedFormContent).map((id) => { if (updatedFormContent[id].label === 'New cohort name...') { isNewCohortAlreadyInTheForm = true; return } })
            if (!isNewCohortAlreadyInTheForm) {
                const uniqueId = uuidv4();
                updatedFormContent[uniqueId] = {
                    id : uniqueId,
                    label: 'New cohort name...',
                    creation_date: moment().toDate(),
                    samples: {},
                    owned_id: props.uid,
                    project_id: props.item.project_id,
                    isNew: true,
                }
                updatedFormContentError[uniqueId] = { 'isInError': false, 'errorMessage': '', 'isDuplicatedSamples': false }
                setFormContentError(updatedFormContentError)
                setformContent(updatedFormContent)

                props.item['request_list'] = updatedFormContent
                props.firebase.NewSampleRegistrationForm().doc(props.item.id).set(props.item)
            } else {
                setSelectNewCohortErrorMessage('New cohort name already exists')
            }
        } else {
            props.firebase.Groups().doc(event.target.value).get()
                .then((cohort) => {
                    updatedFormContent[cohort.id] = cohort.data()
                    updatedFormContent[cohort.id].id = cohort.id
                    updatedFormContent[cohort.id].samples = {}
                    updatedFormContent[cohort.id].isNew = false


                    updatedFormContentError[cohort.id] = { 'isInError': false, 'errorMessage': '', 'isDuplicatedSamples': false }
                    setformContent(updatedFormContent)
                    setFormContentError(updatedFormContentError)

                    props.item['request_list'] = updatedFormContent
                    props.firebase.NewSampleRegistrationForm().doc(props.item.id).set(props.item)

                    var updatedCohortList = {}
                    Object.keys(cohortList).map((key) => {
                        if (cohortList[key] !== cohort.id) {
                            updatedCohortList[key] = cohortList[key]
                        }
                    })
                    setCohortList(updatedCohortList)
                })
        }
    };

    const handleAddNewSample = (cohortId) => {
        let updatedFormContentError = { ...formContentError }
        updatedFormContentError[cohortId].isDuplicatedSamples = false
        setFormContentError(updatedFormContentError)
        let updatedFormContent = { ...formContent }
        let isAlreadyANewSample = false 
        Object.keys(updatedFormContent[cohortId].samples).map((sampleId) => { if(updatedFormContent[cohortId].samples[sampleId].label === 'New sample name...') isAlreadyANewSample = true })
        if(!isAlreadyANewSample) {
            const uniqueId = uuidv4();
            updatedFormContent[cohortId]['samples'][uniqueId] = { id: uniqueId, label: 'New sample name...', creation_date: moment().toDate(), description: '' }
            let updatedFormContentError = { ...formContentError }
            updatedFormContentError[cohortId + uniqueId] = { 'isInError': false, 'errorMessage': '' }
            setFormContentError(updatedFormContentError)
            setformContent(updatedFormContent)
            props.item['request_list'] = updatedFormContent
            props.firebase.NewSampleRegistrationForm().doc(props.item.id).set(props.item)
            .then(() => { setIsFormSaved(true) })
        } else {
            let updatedFormContentError = { ...formContentError }
            updatedFormContentError[cohortId].isDuplicatedSamples = true
            setFormContentError(updatedFormContentError)
        }
    }

    const handleSampleDeletion = (cohortId, sampleId) => {
        setIsFormSaved(false) 
        let updatedFormContent = { ...formContent }
        let updatedCohort = { ...formContent[cohortId] }
        updatedCohort['samples'] = {}
        Object.keys(updatedFormContent[cohortId].samples).map((sid) => {
            if (sid !== sampleId) {
                updatedCohort['samples'][sid] = updatedFormContent[cohortId]['samples'][sid]
            }
        })
        updatedFormContent[cohortId] = updatedCohort
        props.item['request_list'] = updatedFormContent
        props.firebase.NewSampleRegistrationForm().doc(props.item.id).set(props.item)
        .then(() => {
            setIsFormSaved(true) 
            setformContent(updatedFormContent)
        })
    }

    const deleteForm = (formId) => {
        props.firebase.NewSampleRegistrationForm().doc(formId).delete()
            .then(() => {
                props.fetchExistingForms()
            })
            .catch((error) => {
                console.error('Error deleting form:', error);
            });
    }

    const handleCohortDeletion = (cohortId) => {
        console.log(cohortId)
        setIsFormSaved(false)
        let formContentReference = { ...formContent }
        var updatedFormContent = {}
        console.log(formContentReference)
        Object.keys(formContentReference).map((key) => {
            console.log(key, cohortId)
            if (key !== cohortId) {
                updatedFormContent[key] = formContentReference[key]
            }
        })
        setformContent(updatedFormContent)
        if(!formContentReference[cohortId].isNew){
            var cohortListUpdated = { ...cohortList }
            cohortListUpdated[formContentReference[cohortId].label] = cohortId
            setCohortList(cohortListUpdated)
        }
        props.item['request_list'] = updatedFormContent
        props.firebase.NewSampleRegistrationForm().doc(props.item.id).set(props.item)
            .then(() => { setIsFormSaved(true) })
    }


    const handleCancelSubmission = () => {
        setIsFormSaved(false)
        props.item.is_submitted = false
        props.firebase.NewSampleRegistrationForm().doc(props.item.id).set(props.item)
            .then(() => { setIsFormSaved(true) })
    }

    const handleFormValidation = async (formId) => {
        props.firebase.NewSampleRegistrationForm().doc(formId).get()
            .then((form) => {
                Object.keys(form.data().request_list).forEach(async (cohortId) => {
                    
                    let cohort_id = ''
                    if (form.data().request_list[cohortId].isNew) {
                        let cohort = {
                            creation_date:moment().toDate(),
                            last_update:moment().toDate(),
                            project_id: form.data().project_id,
                            owner_id: props.uid,
                            organisation_id: props.user.organisation_id,
                            label: form.data().request_list[cohortId].label,
                        }

                        let cohortRef = await props.firebase.Groups().add(cohort)
                        cohort_id = cohortRef.id
                        console.log('New cohort created : ' + cohort_id)
                    } else {
                        cohort_id = cohortId
                        console.log('Already existing cohort : ' + cohort_id)
                    }

                    Object.keys(form.data().request_list[cohortId].samples).map(async (sampleId) => {
                        let sample = {
                            creation_date:moment().toDate(),
                            last_update:moment().toDate(),
                            cohort_id: cohort_id,
                            project_id: form.data().project_id,
                            owner_id: props.uid,
                            organisation_id: props.user.organisation_id,
                            name: form.data().request_list[cohortId].samples[sampleId].label,
                            description: form.data().request_list[cohortId].samples[sampleId].description,
                            substrat: '',
                            width: 0,
                            height: 0,
                            max_level: 0,
                            modalities: [],
                        }
                        let sampleRef = await props.firebase.Samples().add(sample)
                        console.log('New sample created : ' + sampleRef.id)
                    })

                    setIsFormSaved(false)
                    props.item.is_validated = true
                    props.firebase.NewSampleRegistrationForm().doc(props.item.id).set(props.item)
                        .then(() => { setIsFormSaved(true) })
                })
            })
        }


    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <div id='dialog-trigger-container' >
                    <div id='trigger-dialog-div' style={{ height: '40px' }} onClick={(event) => handleClickOpen(event)}>
                        <p className='form-name-container' >{props.item.name}</p>
                        <p className='project-name-container' >{props.item.project_name}</p>
                        <p className='form-creation-date-container' >{props.item.creation_date.toDate().toLocaleDateString()}</p>
                        <div className='form-status-container'>
                            {props.item.is_submitted ?
                                (props.item.is_validated ?
                                    <Done id='status-icon' />
                                    :
                                    <div className='tooltip'>
                                        <HourglassEmpty id='status-icon' />
                                        <div id="tooltiptext">Pending</div>
                                    </div>
                                )
                                :
                                <div className='tooltip'>
                                    <Edit id='status-icon' />
                                    <div id="tooltiptext">Being edited</div>
                                </div>
                            }
                        </div>

                        {props.item.is_submitted ? (
                            props.item.is_validated ? (
                                <div className='form-state-container'>VALIDATED</div>
                            ) : (
                                props.user.isAdmin ? (
                                    <div className='form-state-container'>
                                        <button
                                            className='action-button'
                                            style={{ width: '90px' }}
                                            onClick={() => { handleFormValidation(props.item.id) }}
                                        >
                                            VALIDATE <Send style={{ fontSize: '16px', cursor: 'pointer' }} />
                                        </button>
                                    </div>
                                ) : (
                                    <div className='form-state-container'>SUBMITTED</div>
                                )
                            )
                        ) : (
                            <div className='form-state-container'>
                                <button
                                    className='action-button'
                                    style={{ width: '90px' }}
                                    onClick={() => { props.handleFormSubmission(props.item.id) }}
                                >
                                    SUBMIT <Send style={{ fontSize: '16px', cursor: 'pointer' }} />
                                </button>
                            </div>
                        )}

                    </div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        scroll={scroll}
                        id="form-dialog"
                        fullWidth={false}
                        PaperProps={{
                            sx: {
                                position: 'relative',
                                overflowY: 'scroll',
                                marginLeft: '200px',
                                width: '800px',
                                maxWidth: '900px',
                                borderRadius: '3px',
                                minHeight: '600px',
                            }
                        }}
                    >
                        <DialogContent sx={{
                            overflowY: 'scroll',
                            '&::-webkit-scrollbar': {
                                width: '8px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            },
                        }}>
                            {!props.item.is_submitted ? <div id='form-saving-status-container'> <Save id='save-form-icon' /> {isFormSaved ? <CheckCircle id='check-form-icon' /> : <Sync id='sync-form-icon'  />} </div> : null}
                            <div id='form-dialog-header'>
                                <TitleTextField
                                    value={formTitle}
                                    error={formTitleError}
                                    fullWidth={true}
                                    variant="standard"
                                    placeholder="Write your form name here"
                                    helperText={formTitleErrorMessage}
                                    disabled = {props.item.is_submitted}
                                    onChange={onChangeFormName}
                                    onBlur={() => { handleFormTitleUpdate() }}
                                />
                                
                                {props.item.is_submitted ? (
                                    props.item.is_validated ? 
                                    (
                                    <div id='form-dialog-header-buttons'>
                                    </div>
                                    ) : (
                                    <div id='form-dialog-header-buttons'>
                                        <button className='action-button' id='deletion-button' style={{ width: '170px' }} onClick={() => { handleCancelSubmission() }} > <Cancel style={{ fontSize: '16px', cursor: 'pointer' }} /> CANCEL SUBMISSION</button>
                                    </div>
                                    )
                                )
                                :
                                <div id='form-dialog-header-buttons'>
                                    <button className='action-button' id='deletion-button' style={{ width: '90px' }} onClick={() => { deleteForm(props.item.id) }} > <Delete style={{ fontSize: '16px', cursor: 'pointer' }} /> DELETE </button>
                                    <button className='action-button' style={{ width: '90px', marginLeft: '25px' }} onClick={() => { props.handleFormSubmission(props.item.id); setOpen(false) }} > SUBMIT <Send style={{ fontSize: '16px', cursor: 'pointer' }} /> </button>
                                </div>
                                }
                            </div>



                            {Object.keys(formContent).map((cohortId, i) => {
                                return (
                                    <div id='cohort-details' key={i} >
                                        <CohortTextField
                                            id="cohort-title"
                                            value={formContent[cohortId].label}
                                            error={formContentError[cohortId].isInError}
                                            fullWidth={false}
                                            disabled = {props.item.is_submitted}
                                            variant="standard"
                                            placeholder="Write your cohort name here"
                                            helperText={formContentError[cohortId].errorMessage}
                                            onChange={(e) => { onChangeCohortName(e, cohortId) }}
                                            onBlur={() => { handleFormContentUpdate(cohortId, null) }}
                                        />
                                        {Object.keys(formContent[cohortId].samples).map((sampleId, j) => {
                                            return (
                                                <div id='sample-details' key={j} >
                                                    <div id='sample-header'>
                                                        <SampleTitleTextField
                                                            id="sample-title"
                                                            value={formContent[cohortId].samples[sampleId].label}
                                                            error={formContentError[cohortId + sampleId].isInError}
                                                            fullWidth={false}
                                                            variant="standard"
                                                            disabled = {props.item.is_submitted}
                                                            placeholder="Write your sample name here"
                                                            helperText={formContentError[cohortId + sampleId].errorMessage}
                                                            onChange={(e) => { onChangeSampleName(e, cohortId, sampleId) }}
                                                            onBlur={() => { handleFormContentUpdate(cohortId, sampleId) }}
                                                        />
                                                        {!props.item.is_submitted ? <Delete id='delete-sample-icon' onClick={() => { handleSampleDeletion(cohortId, sampleId) }} /> :null}
                                                    </div>
                                                    <SampleDetailTextField
                                                        value={formContent[cohortId].samples[sampleId].description}
                                                        fullWidth={true}
                                                        variant="filled"
                                                        rows={2}
                                                        disabled = {props.item.is_submitted}
                                                        placeholder="Sample description..."
                                                        onChange={(e) => { onChangeSampleDescription(e, cohortId, sampleId) }}
                                                        onBlur={() => { handleFormContentUpdate(cohortId, sampleId) }}
                                                        multiline={true}
                                                        InputProps={{
                                                            style: { 
                                                                padding: '0px',
                                                            }
                                                        }}
                                                      
                                                    />
                                                </div>
                                            )
                                        })
                                        }
                                        {!props.item.is_submitted ?
                                            <div id='new-sample-container'>
                                                <div id = 'new-sample-buttons-container'>
                                                    <button className='action-button' style={{ width: '160px' }} onClick={() => { handleAddNewSample(cohortId) }} > ADD A NEW SAMPLE <Send style={{ fontSize: '16px', cursor: 'pointer' }} /> </button>
                                                    <button className='action-button' id='deletion-button' style={{ width: '140px' }} onClick={() => {handleCohortDeletion(cohortId)}} > <Delete style={{ fontSize: '16px', cursor: 'pointer' }} /> REMOVE COHORT</button>
                                                </div>
                                                {formContentError[cohortId].isDuplicatedSamples ? <FormHelperText style={{ color: 'red' }}>{'New sample name already exists, please change it first.'}</FormHelperText> : <FormHelperText >{}</FormHelperText>}
                                                </div>
                                        :<div id='new-sample-container'></div>}
                                    </div>

                                )
                            })
                            }
                            {!props.item.is_submitted ?
                                <div id='new-group-selection-container'>
                                    <FormControl size='small' sx={{ width: '210px'}}>
                                        {newGroup === '' ? 
                                        <InputLabel
                                            id="demo-simple-select-standard-label"
                                            shrink={false}
                                            sx={{ width: '150px',fontSize: '14px'}}
                                        >
                                            Insert a new cohort...
                                        </InputLabel>
                                        : null}
                                        <CustomSelect
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={newGroup}
                                            onChange={handleNewCohortInsertion}
                                            sx={{fontSize: '14px', borderRadius: '2px'}}
                                        >
                                            <MenuItem id='new-cohort-menu-item' value={'new-cohort'}><em>+ New cohort</em></MenuItem>
                                            {Object.keys(cohortList).map((cohortName) => (
                                                <MenuItem id='new-cohort-menu-item'
                                                    value={cohortList[cohortName]}
                                                    key={cohortList[cohortName]}
                                                >
                                                    {cohortName}
                                                </MenuItem>
                                            ))}
                                        </CustomSelect>
                                    </FormControl>
                                    <FormHelperText style={{ color: 'red' }}>{selectNewCohortErrorMessage}</FormHelperText>
                                </div>
                            :null}
                        </DialogContent>
                    </Dialog>
                </div>
            )}
        </AuthUserContext.Consumer>

    );
}

const TitleTextField = styled(TextField)({
    width: '50%',
    marginLeft: '20px',
    '& .MuiInputBase-input': {
        fontSize: '24px',
        fontWeight: '300',
        '&:disabled': {
            '-webkit-text-fill-color': 'black',
          },
    },

    '& .MuiInput-underline:before': {
        borderBottomColor: 'rgb(0, 0, 0)',
        borderBottomStyle: 'solid',
    },

    '& .MuiInput-underline:after': {
        borderBottomColor: 'rgb(50, 90, 200);',
    },
    '&:hover .MuiInput-underline:before': {
        borderBottomColor: 'rgb(50, 90, 200)',
        borderBottomWidth: '1px',
    },
    
    
});


const CohortTextField = styled(TextField)({
    width: 'calc(100%)',
    marginTop: '25px',
    '& .MuiInputBase-input': {
        fontSize: '18px',
        fontWeight: '300',
        '&:disabled': {
            '-webkit-text-fill-color': 'black',
          },
    },
    '& .MuiInput-underline:before': {
        borderBottomColor: 'black',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'rgb(50, 90, 200);',
    },
    '&:hover .MuiInput-underline:before': {
        borderBottomColor: 'rgb(50, 90, 200)',
        borderBottomWidth: '1px',
    },
});

const SampleTitleTextField = styled(TextField)({
    width: '50%',
    marginTop: '15px',
    '& .MuiInputBase-input': {
        fontSize: '13px',
        fontWeight: '400',
        '&:disabled': {
            '-webkit-text-fill-color': 'black',
          },
    },
    '& .MuiInput-underline:before': {
        borderBottomColor: 'black',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'rgb(50, 90, 200);',
    },
    '&:hover .MuiInput-underline:before': {
        borderBottomColor: 'rgb(50, 90, 200)',
        borderBottomWidth: '1px',
    },
});

const SampleDetailTextField = styled(TextField)({
    width: '100%',
    marginTop: '10px',
  
    '& .MuiInputBase-input': {
      fontSize: '13px',
      fontWeight: '300',
      '&:disabled': {
        '-webkit-text-fill-color': 'black',
        
      },
    },
    '& .MuiFilledInput-root': {
      '&:before': {
        borderBottomWidth: '0px'
      },
      '&:hover:before': {
        borderBottomWidth: '0px'
      },
      '&:after': {
        borderBottomWidth: '0px'
      },
    },
    '& .MuiInputBase-inputMultiline': {
      margin: '10px'
    },
  });
  

const CustomSelect = styled(Select)(({ theme }) => ({
    '&.MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#aaa', // Set the default border color here
        },
        '&:hover fieldset': {
            borderColor: 'black', // Set the border color on hover
        },
        '&.Mui-focused fieldset': {
            border: '1px solid rgb(45, 86, 201)', // Set the border color when focused
        },
    },
}));