class Shaders {
    constructor() {

        this.vertex_square = `attribute vec4 a_pos;
                      attribute vec2 a_tile_pos;
                      varying vec2 v_tile_pos;

                      void main() {
                        // Pass the overlay tiles
                        v_tile_pos = a_tile_pos;
                        gl_Position = a_pos;
                      }`;

        this.fragment_sobel = `precision mediump float;
uniform sampler2D u_tile;
//uniform sampler2D u_tile2;
uniform vec2 u_tile_size;
varying vec2 v_tile_pos;


uniform int clamp_lut;

uniform ivec3 rgb_on;
uniform vec3 lut_min_rgb;
uniform vec3 lut_max_rgb;
uniform vec3 scale_rgb;

uniform ivec3 cmy_on;
uniform vec3 lut_min_cmy;
uniform vec3 lut_max_cmy;
uniform vec3 scale_cmy;


// Sum a vector
float sum3(vec3 v) {
  return dot(v,vec3(1));
}

// Weight of a matrix
float weigh3(mat3 m) {
  return sum3(m[0])+sum3(m[1])+sum3(m[2]);
}

// Take the outer product
mat3 outer3(vec3 c, vec3 r) {
  mat3 goal;
  for (int i =0; i<3; i++) {
      goal[i] = r*c[i];
  }
  return goal;
}

//*~*~*~*~*~*~*~*~*~*~*~*~*~
//  Now for the Sobel Program
//*~

// Sample the color at offset
vec3 color(float dx, float dy) {
  // calculate the color of sampler at an offset from position
  return texture2D(u_tile, v_tile_pos+vec2(dx,dy)).rgb;
}

float sobel(mat3 kernel, vec3 near_in[9]) {

  // nearest pixels
  mat3 near_out[3];

  // Get all near_in pixels
  for (int i = 0; i < 3; i++) {
      near_out[i][0] = kernel[0]*vec3(near_in[0][i],near_in[1][i],near_in[2][i]);
      near_out[i][1] = kernel[1]*vec3(near_in[3][i],near_in[4][i],near_in[5][i]);
      near_out[i][2] = kernel[2]*vec3(near_in[6][i],near_in[7][i],near_in[8][i]);
  }

  // convolve the kernel with the nearest pixels
  return length(vec3(weigh3(near_out[0]),weigh3(near_out[1]),weigh3(near_out[2])));
}

//vec3 applySigmoid(float p1, float p2, vec3 val)
//{
//    float k = 0.05 * scale;
//    //return vec3(1.0) / (vec3(1.0) + exp(- k * (vec3(val - vec3(p1)))));

//    //return pow(val, vec3(1.0/2.2));
//    //return pow(val, vec3(p1));

//    vec3 sqr = val - vec3(p1);
//    sqr *= sqr;

//    return exp(sqr / vec3(2.0 * k*k)) / 6.0;
//}

vec3 adjustLUT(vec3 min, vec3 max, vec3 inCol)
{
    vec3 col = inCol;
    //if(clamp_lut == 1)
    {
        col = clamp(inCol, min, max);
        return (col - min) / (max - min);
    }

    //return applySigmoid(min, max, inCol);
}

void main() {
  // Prep work
  //vec3 near_in[9];
  //vec3 mean = vec3(1,2,1);
  //vec3 slope = vec3(-1,0,1);
  //mat3 sobelX = outer3(mean,slope);
  //mat3 sobelY = outer3(slope,mean);
  //vec2 u = vec2(1./u_tile_size.x, 1./u_tile_size.y);
  //// Calculate coordinates of nearest points
  //for (int i = 0; i < 9; i++) {
  //  near_in[i] = color(mod(float(i),3.)*u.x, float(i/3-1)*u.y);
  //}

  // Show the mixed XY contrast
  //float edgeX = sobel(sobelX, near_in);
  //float edgeY = sobel(sobelY, near_in);
  //float mixed = length(vec2(edgeX,edgeY));
//  mixed = (max(mixed,0.5)-0.5);

  vec3 rgb = vec3(rgb_on);
  vec3 cmy = vec3(cmy_on);
   
  vec3 retCol_rgb = texture2D(u_tile, v_tile_pos).rgb * scale_rgb * rgb;
  retCol_rgb = adjustLUT(lut_min_rgb, lut_max_rgb, retCol_rgb);

  //vec3 retCol_cmy = 1.0 - ((1.0 - texture2D(u_tile2, v_tile_pos).rgb) * scale_cmy);
  //retCol_cmy = adjustLUT(lut_min_cmy, lut_max_cmy, retCol_cmy);


  //gl_FragColor = vec4((retCol_rgb + retCol_cmy) * 0.5,1.0);
  gl_FragColor = vec4(retCol_rgb,1.0);

}`
    };

};

export default Shaders;
