
import { withAuthorization } from '../Session';
import { AuthUserContext } from '../Session';
import React, { useEffect, useState, Component, Profiler } from "react";
import OpenSeaDragon from "openseadragon";
// import {Chart, BarElement,} from 'chart.js';
import moment from 'moment'

import tippy from 'tippy.js';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from "@fullcalendar/interaction";


import './styles.css';
import { Chart, registerables } from 'chart.js';
import { element } from 'prop-types';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

Chart.register(...registerables);



class HomePage extends Component {

  calendarRef = React.createRef()

  constructor(props) {
    super(props);

    this.state = {
      username: false,
      id: '',
      Lproj: [],
      Lgroup: [],
      Lsample: [],
      ProjectId: [],
      GroupId: [],
      SampleId:[],
      GroupSize: [],

      Color: ['rgb(47, 75, 124)','rgb(102, 81, 145)','rgb(160, 81, 149)','rgb(212, 80, 135)','rgb(249, 93, 106)','rgb(255, 124, 67)','rgb(255, 166, 0)'],
      ColorPGS: ['rgb(0, 63, 92)','rgb(188, 80, 144)','rgb(255, 166, 0)'],

      uid: false,
      projectList: [],
      imageURL: '',
      viewer: null,

      executed: false,
      graphe1: false,
      graphe2: false,
      graphe3: false,
    };
    this.testDB = this.testDB.bind(this);
  }

  
  componentDidMount() {
    const uid = this.props.firebase.auth.currentUser.uid
    this.setState({uid:uid})
    document.title = 'Home'

    // var imageRef = this.props.firebase.Storage().ref('images/overview4 (1).jpg')
    // imageRef.getDownloadURL()
    // .then((result) => {
    //   this.setState({imageURL:result})
    //   console.log(result)
    // })

    // var timeSel = document.getElementById("selected-time");
    // timeSel.onchange = this.OnChangeSelected;

    // var valSel = document.getElementById("selected-val");
    // valSel.onchange = this.OnChangeSelectedVal;

    // var costSel = document.getElementById("selected-time-cost");
    // costSel.onchange = this.OnChangeSelectedCost;



    // this.setState({viewer:OpenSeaDragon({
    //   id: "openSeaDragon",
    //   prefixUrl: "openseadragon-images/",
    //   animationTime: 1,
    //   blendTime: 0.5,
    //   constrainDuringPan: true,
    //   maxZoomPixelRatio: 1,
    //   minZoomLevel: 1,
    //   visibilityRatio: 1,
    //   zoomPerScroll: 2,
    //   tileSources: duomo,
    //   navigatorPosition:   "TOP_RIGHT",
    //   showNavigator:  true,
    //   nextButton:     "next",
    //   previousButton: "previous",
    //  })
    // })
  };

  testDB() {

    this.props.firebase.Samples().where('owner_id','==',this.state.id).get()
    .then((result) => {
      result.forEach(element => {
        var isIn = false;
        this.state.Lsample.forEach(e => {          
          if(e.name == element.data().name && e.project_id == element.data().project_id && e.group_id == element.data().group_id ) {
            isIn = true;
          } 
        })

        //Si ce n'est pas le cas, on l'ajoute à la liste
        if(!isIn) {
          this.state.Lsample.push(element.data());

          var an = element.data().last_update.toDate().getFullYear().toString();
          var mois = element.data().last_update.toDate().getMonth().toString();
          var jour = element.data().last_update.toDate()

          if(mois.length < 2) {
            mois = '0'+ mois;
          }
          if(jour.length < 2) {
            jour = '0'+ jour;
          }
        }
      });
    })

    // this.props.firebase.Projects().where('owner_id','==','manon.poulain').get()
    // .then((result) => {
    //   console.log('res size :',result.size);
    //   console.log('res docs :',result.docs);
    //   console.log('res query :',result.query);
    //   console.log('res empty :',result.empty);
    // })


    // var usr = [];
    // this.props.firebase.Users().where('username', '==', 'manon.poulain').get()
    // .then((result) => {
    //   result.forEach(element => {
    //     this.setState({id : element.id});
    //     usr.push(element);
    //     console.log("usr :",element.id);
    //   });
    // })

    // for(let u of usr) {
    //   console.log("id usr :",u.id);
    //   console.log("usr :",u);
    // }

  }


  getUserID(username) {
    var usr = [];
    this.props.firebase.Users().where('username', '==', username).get()
    .then((result) => {
      result.forEach(element => {
        this.setState({id : element.id});
        usr.push(element);
      });
    })
  }

  getUserProjects() {
    this.props.firebase.Projects().where('owner','==',this.state.id).get()
    .then((result) => {

      result.forEach(element => {
        var isIn = false;
        //On regarde si l'élément est déjà dedans
        this.state.Lproj.forEach(e => {
          if(e.name == element.data().name) {
            isIn = true;
          } 
        })

        //Si ce n'est pas le cas, on l'ajoute à la liste
        if(!isIn) {
          this.state.Lproj.push(element.data());
          this.state.ProjectId.push({id: element.id, data: element.data()});
        }

        //Et on trie la liste de la plus ancienne update à la plus récente
        this.state.Lproj.sort(function(e1,e2) {
          return((e1.last_update - e2.last_update));
        })
        this.state.ProjectId.sort(function (p1,p2) {
          return p1.data.last_update - p2.data.last_update
        });
      });
    })
  }


  getUserGroups() {
    this.props.firebase.Groups().where('owner_id','==',this.state.id).get()
    .then((result) => {
      result.forEach(element => {
        var isIn = false;
        
        //On regarde si l'élément est déjà dedans
        this.state.Lgroup.forEach(e => {
          if(e.name == element.data().name && e.project_id == element.data().project_id) {
            isIn = true;
          } 
        })

        //Si ce n'est pas le cas, on l'ajoute à la liste
        if(!isIn) {
          this.state.Lgroup.push(element.data());
          this.state.GroupId.push({id: element.id, data: element.data()});
        }

        //Et on trie la liste de la plus récente update à la plus ancienne
        this.state.Lgroup.sort(function(g1,g2) {
          return(-(g1.last_update - g2.last_update))
        })
      });
    })
  }


  getUserSamples() {
    this.props.firebase.Samples().where('owner_id','==',this.state.id).get()
    .then((result) => {
      result.forEach(element => {
        var isIn = false;
        
        //On regarde si l'élément est déjà dedans; cad soit ils sont dans le même projet et le même groupe
        this.state.Lsample.forEach(e => {          
          if(e.name == element.data().name && e.project_id == element.data().project_id && e.group_id == element.data().group_id ) {
            isIn = true;
          } 
        })

        //Si ce n'est pas le cas, on l'ajoute à la liste
        if(!isIn) {
          this.state.Lsample.push(element.data());
          this.state.SampleId.push({id: element.id, data: element.data()});
        }

        //Et on trie la liste de la plus récente update à la plus ancienne
        this.state.Lsample.sort(function(s1,s2) {
          return(-(s1.last_update - s2.last_update))
        })
      });
    })
  }

  getIdSamples() {
    this.props.firebase.Samples().where('owner_id','==',this.state.id).get()
    .then((result) => {
      result.forEach(element => {
        var isIn = false;
        
        //On regarde si l'élément est déjà dedans; cad soit ils sont dans le même projet et le même groupe
        this.state.SampleId.forEach(e => {          
          if(e.id == element.id ) {
            isIn = true;
          } 
        })

        //Si ce n'est pas le cas, on l'ajoute à la liste
        if(!isIn) {
          this.state.SampleId.push({id: element.id, data: element.data()});
        }

        //Et on trie la liste de la plus récente update à la plus ancienne
        this.state.SampleId.sort(function(s1,s2) {
          return(-(s1.last_update - s2.last_update))
        })
      });
    })
  }

  getList = () => {
    this.getUserID('manon.poulain');
    this.getUserProjects();
    this.getUserGroups();
    this.getUserSamples();
    this.getIdSamples();
  }

  GetSortedList = (liste) => {
    var donnees = [];

    for(let e1 of liste) {
      var isIn = false;
      var iter = 1;

      for(let e2 of liste) {
        var sameName = (e1.name === e2.name);
        var sameUpdate = (e1.last_update.toDate().toString().substring(4, 10) === e2.last_update.toDate().toString().substring(4, 10))

        if(e1.project_id != undefined) {
          var sameProject = (e1.project_id === e2.project_id);

          if(e1.group_id != undefined) {
            var sameGroup = (e1.project_id === e2.project_id);

            if(!sameName && sameProject && sameGroup && sameUpdate) {
              iter = iter + 1;
            }
          }
          else {
            if(!sameName  && sameProject && sameUpdate) {
              iter = iter + 1;
            }
          }
        }
        else {
          if(!sameName && sameUpdate) {
            iter = iter + 1;
          }
        }
      }
      var couple = [e1.last_update.toDate().toString().substring(4, 10),iter];
      for (let i in donnees) {
        if(donnees[i][0].includes(couple[0])) {
          isIn = true;
        }
      }

      if (donnees.length === 0 || !isIn) {
        donnees.push(couple)
      }

    }

    donnees.sort();
    return(donnees)
  }

  GetSortedListMonths = (liste) => {
    var donnees = [];
    var iter = 1;

    for(let e1 of liste) {
      var isIn = false;

      for(let e2 of liste) {
        var sameName = (e1.name === e2.name);
        var sameUpdate = (e1.last_update.toDate().toString().substring(4, 7) === e2.last_update.toDate().toString().substring(4, 7))

        if(e1.project_id != undefined) {
          var sameProject = (e1.project_id === e2.project_id);

          if(e1.group_id != undefined) {
            var sameGroup = (e1.project_id === e2.project_id);

            if(!sameName && sameUpdate) {
              iter = iter + 1;
            }
            else if(sameName && (!sameGroup || !sameProject) && sameUpdate) {
              iter = iter + 1;
            }
          }
          else {
            if(!sameName && sameUpdate) {
              iter = iter + 1;
            }
            else if(sameName && !sameProject && sameUpdate) {
              iter = iter + 1;
            }
          }
        }
        else {
          if(!sameName && sameUpdate) {
            iter = iter + 1;
          }
        }
      }

      var couple = [e1.last_update.toDate().toString().substring(4, 7),iter];
      for (let i in donnees) {
        if(donnees[i][0].includes(couple[0])) {
          isIn = true;
        }
      }

      if (donnees.length === 0 || !isIn) {
        donnees.push(couple)
      }

    }

    donnees.sort(function(a,b){
      return -(new Date(b) - new Date(a));
    });
    return(donnees)
  }
  


  TestGetSortedListMonths = (liste) => {
    var donnees = [];
    var ids_stocked = [];
    var abs = [];

    for(let elem of liste) {

      var date = elem.data.last_update.toDate().toString().substring(4, 7);



      //Si on a pas encore stockée la date dans la liste, on la stocke couplée avec le nombre d'itération d'éléments ayant cette date (donc 1)
      if(!abs.includes(date) || abs.length === 0) {
        ids_stocked.push(elem.id);

        var couple = [date,1];
        abs.push(date);
        donnees.push(couple);
      }

      //Si elle a déjà été stockée, on itère de 1
      else if(abs.includes(date)) {
        var indice = 0;
        for(let i in donnees) {
          if(donnees[i][0]===date) {
            indice = i;
          }
        }
        donnees[indice][1] = donnees[indice][1] +1 ;
      }
    }

    //Puis on trie les données du plus ancien au plus récent
    donnees.sort(function(a,b){
      return -(new Date(b) - new Date(a));
    });
    return(donnees)
  }



  OrderAbs = (l1,l2,l3) => {
    //On prend trois listes d'un type bien précis (chaque élément est une liste [date,iter])
    var abs = [];

    //On stocke chaque élément[0] (chaque date) de la première liste
    for(let elem of l1) {
      abs.push(elem[0])
    }
    //Puis on regarde pour chaque date de la seconde et troisième liste si elle est déjà incluse ou non
    for(let elem of l2) {
      if(!abs.includes(elem[0])) {
        abs.push(elem[0])
      }
    }
    for(let elem of l3) {
      if(!abs.includes(elem[0])) {
        abs.push(elem[0])
      }
    }

    //Puis on trie les dates de la plus ancienne à la plus récente et on renvoit l'abscisse ainsi obtenue
    abs.sort(function(a,b){
      return -(new Date(b) - new Date(a));
    })
    return(abs);
  }

  OrderOrd = (abs,l) => {
    //on prend une liste d'abscisse et une liste de liste du type [abs,y]
    var ord = []
    var isIn = false;

    //Pour chaque élément de l'abscisse, on regarde si cet élément est aussi dans l'autre liste
    for(let date of abs) {
      isIn= false;
      for(let elem of l) {
        if(elem[0] === date) {
          ord.push({x: elem[0], y: elem[1]})
          isIn = true;
        }
      }
      //Si ce n'est pas le cas, on considère qu'en cet abscisse, l'ordonnée est nulle
      if(!isIn) {
        ord.push({x: date, y: 0})
      }
    }

    //on trie la liste par date d'ancienneté et on retourne une liste d'élement du type {x,y} (convenable pour un dataset)
    ord.sort(function(a,b){
      return -(new Date(b) - new Date(a));
    });
    return(ord);
  }



  AddEveryDayOfMonth = (l,month,day) => {
    //On prend une liste à laquelle on veut ajouter les jours manquant afin d'obtenir des données pour tous les jours d'un mois
    //Il faut aussi le mois et le dernier jour 
    var jour = '01';

    //on parcourt chaque jour entre le premier du mois et le dernier jour (donnée en argument)
    for(let i = 1;i <= parseInt(day);i+=1) {
      jour = i.toString();
      if(jour.length < 2) {
        jour = '0' + jour;
      }
      jour = month +' '+jour;
      //s'il n'est pas dans la liste, on ajoute la date, pour avoir quelque chose du type 'mois jour'
      if(!l.includes(jour)) {
        l.push(jour)
      }
    }

    l.sort(function(a,b){
      return -(new Date(b) - new Date(a));
    })
    return(l);
  }

  AddEveryDayOfWeek = (l,month,day) => {
    //même principe que la fonction précédente, mais sur une semaine
    const year = [{month:'Jan',day:31},{month:'Feb',day:28},
      {month:'Mar',day:31},{month:'Apr',day:30},
      {month:'May',day:31},{month:'Jun',day:30},
      {month:'Jul',day:31},{month:'Aug',day:31},
      {month:'Sep',day:30},{month:'Oct',day:31},
      {month:'Nov',day:30},{month:'Dec',day:31}
    ];
    var jour = '01';

    //si le dernier jour est < 7, on va donc devoir changer de mois
    if(day < 7) {

      var reste_x_jours = 7 - parseInt(day);

      for(let i = 1; i <= parseInt(day); i+=1) {
        jour = i.toString();
        if(jour.length < 2) {
          jour = '0' + jour;
        }
        jour = month +' '+jour;
        if(!l.includes(jour)) {
          l.push(jour)
        }
      }

      //On passe au mois précédent
      for(let i in year) {
        if(year[i].month === month) {
          month = year[i-1].month;
          day = year[i-1].day;
        }
      }

      //et on ajoute les jours restants
      for(let i = parseInt(day)-reste_x_jours + 1; i <= parseInt(day); i+=1) {
        jour = i.toString();
        if(jour.length < 2) {
          jour = '0' + jour;
        }
        jour = month +' '+jour;
        if(!l.includes(jour)) {
          l.push(jour)
        }
      }

    }
    //sinon, pas de soucis, on procède comme vu sur la fonction AddEveryDayOfMonth
    else {
      for(let i = parseInt(day) - 6; i <= parseInt(day); i+=1) {
        jour = i.toString();
        if(jour.length < 2) {
          jour = '0' + jour;
        }
        jour = month +' '+jour;
        if(!l.includes(jour)) {
          l.push(jour)
        }
      }
    }

    l.sort(function(a,b){
      return -(new Date(b) - new Date(a));
    })
    return(l);
  }

  AddMissingAbsDays = (abs) => {
    const longeur = abs.length;
    abs.sort(function(a,b){
      return -(new Date(b) - new Date(a));
    })

    const year = [{month:'Jan',day:31},{month:'Feb',day:28},
      {month:'Mar',day:31},{month:'Apr',day:30},
      {month:'May',day:31},{month:'Jun',day:30},
      {month:'Jul',day:31},{month:'Aug',day:31},
      {month:'Sep',day:30},{month:'Oct',day:31},
      {month:'Nov',day:30},{month:'Dec',day:31}
    ];
    var day = '01';
    var month = 'Jan';

    //Si il y a moins d'éléments que de jours voulus, on doit compléter la liste avec les données manquantes
    var indice = 0;
    var dernier_jour = abs[longeur - 1];
    month = dernier_jour.substring(0, 3);
    day = dernier_jour.substring(4, 7);

    abs = this.AddEveryDayOfMonth(abs,month,day);

    for(let i in year) {
      if (year[i].month === month) {
        indice = i;
      }
    }
    abs = this.AddEveryDayOfMonth(abs,year[indice-1].month,year[indice-1].day);    
    abs = abs.slice(-30);

    return(abs);
  }

  AddMissingAbsWeek = (abs) => {
    const longeur = abs.length;
    var dernier_jour = abs[longeur - 1];
    var month = dernier_jour.substring(0, 3);
    var day = dernier_jour.substring(4, 7);

    abs = this.AddEveryDayOfWeek(abs,month,day);

    abs.sort(function(a,b){
      return -(new Date(b) - new Date(a));
    })
    abs = abs.slice(-7);
    return(abs);
  }

  AddMissingAbsMonth = (abs) => {
    const longeur = abs.length;
    var debut = 0;
    var liste = []

    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

    if (longeur < 3) {

      for(let i in months) {
        if(months[i]===abs[abs.length - 1]) {
          debut = i;
        }
      }

      for (let i = 1; i <= 3 - longeur; i+=1) {
        if (debut - i < 0) {
          debut = 12;
        }
        liste = [months[debut - i]].concat(liste);
      }

      abs = liste.concat(abs);
    }

    abs = abs.sort(function(a,b){
      return -(new Date(b + '2022') - new Date(a + '2022'));
    });;
    abs = abs.slice(-3);

    return(abs);

  }


  ColorPick = (compteur) => {
    const long = (this.state.Color).length;

    if(compteur > long) {
      compteur = compteur%long;
    }

    return(this.state.Color[compteur])
  }



  GraphCountElem = (optVal) => {
    if((this.state.Lproj).length != 0) {
      //On attends que la requête ait réussi, donc que la liste Lproj ne soit pas vide.
      var donnees_proj= [];
      var donnees_group = [];
      var donnees_sample = [];
      var abs = [];
      
      //on récupère l'emplacement du Canvas de la page web, où on placera le graphe
      const ctx = document.getElementById('myChart').getContext('2d');

      const data = {
        datasets: []
      };

      var data_projet = {
        order: 1,
        label: 'Projets',
        data: [],
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgb(0, 0, 0)',
        borderWidth: 2
      };
      var data_group = {
        order: 2,
        label: 'Groups',
        data: [],
        fill: false,
        backgroundColor: 'rgb(192, 75, 192)',
        borderColor: 'rgb(0, 0, 0)',
        borderWidth: 2
      };
      var data_sample = {
        order: 3,
        label: 'Samples',
        data: [],
        fill: false,
        backgroundColor: 'rgb(192, 192, 75)',
        borderColor: 'rgb(0, 0, 0)',
        borderWidth: 2
      };

      //On regarde l'option choisie. Si l'option n'est pas spécifiée, on se placera naturellemebt sur l'option "week"
      if(optVal==='week' || optVal === undefined) {
        //et on utilise les fonctions définies précédemment pour compter le nombre d'éléments
        donnees_proj = this.GetSortedList(this.state.Lproj);
        donnees_group = this.GetSortedList(this.state.Lgroup);
        donnees_sample = this.GetSortedList(this.state.Lsample);

        //ensuite, on ordonne la liste des abscisses, puis on ajoute les possibles données manquantes
        abs = this.OrderAbs(donnees_proj,donnees_group,donnees_sample);
        abs = this.AddMissingAbsWeek(abs);
      }

      else if(optVal==='months') {
        donnees_proj = this.GetSortedListMonths(this.state.Lproj);
        donnees_group = this.TestGetSortedListMonths(this.state.GroupId);
        donnees_sample = this.TestGetSortedListMonths(this.state.SampleId);

        abs = this.OrderAbs(donnees_proj,donnees_group,donnees_sample);
        abs = this.AddMissingAbsMonth(abs);
      }

      else {
        donnees_proj = this.GetSortedList(this.state.Lproj);
        donnees_group = this.GetSortedList(this.state.Lgroup);
        donnees_sample = this.GetSortedList(this.state.Lsample);

        abs = this.OrderAbs(donnees_proj,donnees_group,donnees_sample);
        abs = this.AddMissingAbsDays(abs);
      }

      //on stocke ensuite les ordonnées triées
      data_projet.data = this.OrderOrd(abs,donnees_proj);
      data_group.data = this.OrderOrd(abs,donnees_group);
      data_sample.data = this.OrderOrd(abs,donnees_sample);

      //On pousse les dataset créés dans celui qui les regroupe tous
      data.datasets.push(data_projet);
      data.datasets.push(data_group);
      data.datasets.push(data_sample);

      for(let i in data.datasets) {
        data.datasets[i].backgroundColor = this.state.ColorPGS[i];
      }

      const config = {
        type: 'bar',
        data: data,
        options: {
          plugins: {
            title: {
              display: true,
              text: 'Dernières modifications en date'
            }
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              beginAtZero: true,
              title: {
                display: true,
                text: 'Nombre de projets modifiés'
              }
            }
          }
        }
      };

      //Si l'option est "months", on n'empile plus les données les unes sur les autres
      if(optVal==='months') {
        config.options.scales.x.stacked = false;
        config.options.scales.y.stacked = false ;
      }

      //on regarde si le graphe a déjà été crée. Si c'est le cas, on doit d'abord détruire l'ancien avant de refaire un nouveau graphe avec les données
      var alreadyExist = Chart.getChart("myChart");

      if(alreadyExist != undefined) {
        alreadyExist.destroy();
      }

      const myChart = new Chart(ctx,config);
      this.setState({graphe1: true})
    }

  }


  GraphCountCost = (optVal) => {
    if((this.state.Lproj).length != 0) {
      var abs = [];

      const ctx = document.getElementById('mySecondChart').getContext('2d');

      const data = {
        datasets: []
      };

      var data_total= {
        label: 'total',
        data: [],
        fill: false,
        backgroundColor: 'rgb(100, 145, 0)',
        borderColor: 'rgb(0, 0, 0)',
        borderWidth: 2,
      };


      //si GroupSize n'a pas encore été rempli, on génère aléatoirement une taille et un état d'activité (0 ou 1) pour chaque groupe et on stocke ça dans GroupSize
      if ((this.state.GroupSize).length != (this.state.GroupId).length) {
        for (let g of this.state.GroupId) {
          var sum_size = 0;
          var active = Math.floor(Math.random() * 2);
          for(let s of this.state.Lsample) {
            if(s.group_id === g.id) {
              sum_size += Math.floor(Math.random() * 500);
            }
          }
  
          this.state.GroupSize.push({id:g.id,data:g.data, size: sum_size, active: active});
        }
      }


      //On regarde l'option, et en fonction, on trie par groupe ou par projet
      if(optVal==="project" || optVal === undefined || optVal===null) {
        for(let elem of this.state.ProjectId) {
          var id = elem.id;
          var sum_size = 0;
          var dataset = {
            label: elem.data.name,
            data: [],
            fill: false,
            backgroundColor: 'rgb(155, 155, 55)',
            borderColor: 'rgb(0, 0, 0)',
            borderWidth: 2,
          }
  
          for (let g of this.state.GroupSize) {
            if(g.data.project_id === id) {
              for(let s of this.state.Lsample) {
                if(s.group_id === g.id) {
                  sum_size += g.size;
                }
              }
            }
          }
  
          dataset.data.push({x: elem.data.name,y:sum_size});
          data.datasets.push(dataset);
  
          abs.push(elem.data.name);
        }
      }


      else if(optVal==="group" ) {
        var compt = 0;
        for(let elem of this.state.ProjectId) {
          var id = elem.id;
  
          for (let g of this.state.GroupSize) {
            var sum_size = 0;
            var dataset = {
              label: g.data.name,
              data: [],
              fill: false,
              backgroundColor: 'rgb(150, 66, 130)',
              borderColor: 'rgb(0, 0, 0)',
              borderWidth: 2,
            }

            if(g.data.project_id === id) {
              for(let s of this.state.Lsample) {
                if(s.group_id === g.id) {
                  sum_size += g.size;
                }
              }

              dataset.data.push({x: elem.data.name,y:sum_size});
              dataset.backgroundColor = this.state.Color[compt];
              data.datasets.push(dataset);
            }

          }
  
          abs.push(elem.data.name);
          data_total.backgroundColor = this.state.Color[6];
          compt += 1;
        }
      }

      var longeur = (data.datasets).length;
      var sum = 0;

      //Puis on fait la somme de tous les poids
      for(let i = 0; i < longeur; i+=1) {
        sum += data.datasets[i].data[0].y;
      }

      data_total.data.push({x: 'Poids total', y: sum});
      data.datasets.push(data_total);


      if(optVal === 'project' || optVal === undefined || optVal===null) {
        for(let i in data.datasets) {
          data.datasets[i].backgroundColor =  this.ColorPick(i);
        }
      }

      data_total.backgroundColor = this.state.Color[6];

      const config = {
        type: 'bar',
        data: data,
        options: {
          plugins: {
            title: {
              display: true,
              text: 'Taille des données selon les projets'
            },
            legend: {
              display: true,
              position: 'right',
              align: 'start',
              reverse: true,
            },

          },
          scales: {
            x: {
              stacked: true,
              title: {
                display: true,
              }
            },
            y: {
              stacked: true,
              beginAtZero: true,
              title: {
                display: true,
                text: 'Poids en MB'
              }
            }
          }
        }
      };

      var alreadyExist = Chart.getChart("mySecondChart");
      if(alreadyExist != undefined) {
        alreadyExist.destroy();
      }

      const mySecondChart = new Chart(ctx,config);
      this.setState({graphe2: true})
    }
  }


  GraphCountPrice = (optVal) => {
    if((this.state.Lproj).length != 0) {
      var abs = [];

      const ctx = document.getElementById('myThirdChart').getContext('2d');

      const data = {
        datasets: []
      };

      var data_total= {
        label: 'total',
        data: [],
        fill: false,
        backgroundColor: 'rgb(100, 145, 0)',
        borderColor: 'rgb(0, 0, 0)',
        borderWidth: 2,
      };

      if ((this.state.GroupSize).length != (this.state.GroupId).length) {
        for (let g of this.state.GroupId) {
          var sum_size = 0;
          var active = Math.floor(Math.random() * 2);
          for(let s of this.state.Lsample) {
            if(s.group_id === g.id) {
              sum_size += Math.floor(Math.random() * 500);
            }
          }
  
          this.state.GroupSize.push({id:g.id,data:g.data, size: sum_size, active: active});
        }
      }

      if(optVal==="week" || optVal === undefined || optVal===null) {
        for(let elem of this.state.ProjectId) {
          var id = elem.id;
          var sum_size = 0;
          var prix = 0;

          var dataset = {
            label: elem.data.name,
            data: [],
            fill: false,
            backgroundColor: 'rgb(155, 155, 55)',
            borderColor: 'rgb(0, 0, 0)',
            borderWidth: 2,
          }

  
          for (let g of this.state.GroupSize) {
            if(g.data.project_id === id) {
              if(g.active == 0) {
                //Si on c'est actif on paye plus cher
                prix += (g.size * 0.01) * 0.5;
              }
              else {
                prix += (g.size * 0.01) * 0.1;
              }
            }
          }
  
          dataset.data.push({x: elem.data.name,y:prix});
          data.datasets.push(dataset);
  
          abs.push(elem.data.name);
        }
      }

      else if(optVal==="groups") {
        var compt = 0;

        for(let elem of this.state.ProjectId) {
          var id = elem.id;

          for (let g of this.state.GroupSize) {
            var prix = 0;
            var dataset = {
              label: g.data.name,
              data: [],
              fill: false,
              backgroundColor: 'rgb(155, 155, 55)',
              borderColor: 'rgb(0, 0, 0)',
              borderWidth: 2,
            }
  
            if(g.data.project_id === id) {
              if(g.active == 0) {
                //Si on c'est actif on paye plus cher
                prix += (g.size * 0.01) * 0.5;
              }
              else {
                prix += (g.size * 0.01) * 0.1;
              }
              dataset.data.push({x: elem.data.name,y:prix});
              dataset.backgroundColor = this.state.Color[compt];
              data.datasets.push(dataset);
            }
          }

          abs.push(elem.data.name);
          data_total.backgroundColor = this.state.Color[6];
          compt += 1;
        }
      }

      else if(optVal==="dual" ) {

        for(let elem of this.state.ProjectId) {
          var id = elem.id;
          var prix_actif = 0;
          var prix_inactif = 0;

          var dataset = {
            label: elem.data.name,
            data: [],
            fill: false,
            backgroundColor: 'rgb(150, 66, 130)',
            borderColor: 'rgb(0, 0, 0)',
            borderWidth: 2,
          }
  
          for (let g of this.state.GroupSize) {

            if(g.data.project_id === id) {
              if(g.active == 0) {
                //Si on c'est actif on paye plus cher
                prix_actif += (g.size * 0.01) * 0.5;
              }
              else {
                prix_inactif += (g.size * 0.01) * 0.1;
              }
            }
          }
          dataset.data.push({x: 'actif',y:prix_actif},{x: 'inactif',y:prix_inactif});
          data.datasets.push(dataset);
        }

        abs.push('actif');
        abs.push('non-actif');
        data_total.backgroundColor = 'rgb(192, 100, 170)';
      }


      else if(optVal==='months') {
        var proj = [];
        const trimestres = {trimestre1 : ['Jan','Feb','Mar'], trimestre2 : ['Apr','May','June'], 
          trimestre3 : ['Jul','Aug','Sep'], trimestre4 : ['Oct','Nov','Dec']
        };

        const trmstr = ['Trimestre 1','Trimestre 2','Trimestre 3','Trimestre 4'];

        for(let elem of this.state.ProjectId) {
          var id = elem.id;
          var sum_size = 0;
          var prix = 0;
  
          for (let g of this.state.GroupSize) {
            if(g.data.project_id === id) {
              if(g.active == 0) {
                //Si on c'est actif on paye plus cher
                prix += (g.size * 0.01) * 0.5;
              }
              else {
                prix += (g.size * 0.01) * 0.1;
              }
            }
          }

          proj.push({nom: elem.data.name, prix:prix, up: elem.data.last_update})
        }

        for(let p of proj) {
          var ord = [];
          var abs = [];
          var test = [];

          var dataset = {
            label: p.nom,
            data: [],
            fill: false,
            backgroundColor: 'rgb(155, 155, 55)',
            borderColor: 'rgb(0, 0, 0)',
            borderWidth: 2,
          };

          var date = p.up.toDate().toString().substring(4, 7);

          if((trimestres.trimestre1).includes(date)) {
            ord.push([trmstr[0],0]);


            for(let i in trmstr) {
              if(i != 0) {
                ord.push([trmstr[i],0]);

              }
            }
          }

          else if((trimestres.trimestre2).includes(date)) {
            ord.push([trmstr[1],p.prix]);

            for(let i in trmstr) {
              if(i != 1) {
                ord.push([trmstr[i],0]);
              }
            }
          }

          else if((trimestres.trimestre3).includes(date)) {
            ord.push([trmstr[2],p.prix]);

            for(let i in trmstr) {
              if(i != 2) {
                ord.push([trmstr[i],0]);
              }
            }
          }

          else {
            ord.push([trmstr[3],p.prix]);

            for(let i in trmstr) {
              if(i != 3) {
                ord.push([trmstr[i],0]);
              }
            }
          }

          test = this.OrderOrd(trmstr, ord);

          for(let i in test) {
            dataset.data.push(test[i]);
          }
          data.datasets.push(dataset);
        }
        
      }

      var longeur = (data.datasets).length;
      var sum = 0;

      if(optVal==="dual") {
        for(let i = 0; i < longeur; i+=1) {
          sum += data.datasets[i].data[0].y + data.datasets[i].data[1].y;
        }
      }

      else if(optVal==="months") {
        for(let elem of data.datasets) {
          for(let d of elem.data) {
            sum += d.y;
          }
        }

      }

      else {
        for(let i = 0; i < longeur; i+=1) {
          sum += data.datasets[i].data[0].y;
        }
      }

      data_total.data.push({x: 'Prix total', y: sum});
      data.datasets.push(data_total);


      if(optVal != 'groups') {
        for(let i in data.datasets) {
          data.datasets[i].backgroundColor =  this.ColorPick(i);
        }
      }

      data_total.backgroundColor = this.state.Color[6];

      const config = {
        type: 'bar',
        data: data,
        options: {
          plugins: {
            title: {
              display: true,
              text: 'Coût des projets'
            },
            legend: {
              display: true,
              position: 'right',
              align: 'center',
              reverse: true,
            }
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              beginAtZero: true,
              title: {
                display: true,
                text: 'Prix (€)'
              }
            }
          }
        }
      };

      var alreadyExist = Chart.getChart("myThirdChart");

      if(alreadyExist != undefined) {
        alreadyExist.destroy();
      }

      const myThirdChart = new Chart(ctx,config);
      this.setState({graphe3: true})
    }
  }



  OnChangeSelected = () => {
    var timeSel = document.getElementById("selected-time").value;
    this.GraphCountElem(timeSel);
  }

  OnChangeSelectedVal = () => {
    var optSel = document.getElementById("selected-val").value;
    this.GraphCountCost(optSel);
  }

  OnChangeSelectedCost = () => {
    var optSel = document.getElementById("selected-time-cost").value;
    this.GraphCountPrice(optSel);
  }


  GraphTimeout = () => {
    if ((this.state.Lproj).length === 0) {
      this.getList();
    }

    if(!this.state.graphe1) {
      this.GraphCountElem();
    }
  }

  GraphTimeout2 = () => {
    if ((this.state.Lproj).length === 0) {
      this.getList();
    }


    if(!this.state.graphe2) {
      this.GraphCountCost();
    }

  }

  GraphTimeout3 = () => {
    if ((this.state.Lproj).length === 0) {
      this.getList();
    }

    if(!this.state.graphe3) {
      this.GraphCountPrice();
    }
  }

  handleEventClick = (info) => { 
    tippy(info.el, {
      content: info.event.extendedProps.description,
      placement: 'bottom',
      trigger: 'click',
      triggerTarget: info.el,
      duration: 0,
      arrow: true,
      theme: 'desc',
    });
  }

  handleHover = (info) => {
    tippy(info.el, {
      content: info.event.title,
      placement: 'top',
      duration: 0,
      arrow: true,
      theme: 'name',
    });

  }

  ClickOnCustom = (info) => {
    tippy('#color-calendar', {
      content: 'Passez la souris sur un événement pour voir son nom complet',
      placement: 'top',
      trigger:'click',
      triggerTarget: info.target,
      duration: 0,
      theme: 'info',
    });
    tippy('#color-calendar', {
      content: "Cliquez sur un événement pour plus d'informations. Cliquez hors de l'événement pour les faire disparaître",
      placement: 'bottom',
      trigger:'click',
      triggerTarget: info.target,
      duration: 0,
      theme: 'info',
    });
  }


  FormatDate = (last_update) => {
    var date = '';
    var annee = last_update.toDate().getFullYear().toString();
    var mois = (last_update.toDate().getMonth() + 1).toString();
    var jour = last_update.toDate().getDate().toString();

    if(mois.length < 2) {
      mois = '0' + mois;
    }
    if(jour.length < 2) {
      jour = '0' + jour;
    }

    date = annee + '-' + mois + '-' + jour;

    return(date);
  }

  FormatMoment = () => {
    var date = '';
    var moment = new Date();

    var annee = moment.getFullYear().toString();
    var mois = (moment.getMonth() + 1).toString();
    var jour = moment.getDate().toString();

    if(mois.length < 2) {
      mois = '0' + mois;
    }
    if(jour.length < 2) {
      jour = '0' + jour;
    }

    date = annee + '-' + mois + '-' + jour;

    return(date);
  }


  AddEventToList = () => {
    if((this.state.SampleId).length != 0) {
        if(!this.state.executed) {
          let calendarApi = this.calendarRef.current.getApi()
          var moment = this.FormatMoment();

          for(let sample of this.state.SampleId) {
            var string_date = sample.data.last_update.toDate().toString();
            var date = this.FormatDate(sample.data.last_update);
            var couleur = 'green';

            var desc ="L'analyse de l'échantillon "+'"'+sample.data.name+'"'+" est prévue le "+string_date.substring(4,15)+" à "+string_date.substring(16,18)+'h'+string_date.substring(19,21);

            if(date < moment) {
              couleur = 'rgb(226, 54, 54)';
              desc ="L'analyse de l'échantillon "+'"'+sample.data.name+'"'+" a été effectuée le "+string_date.substring(4,15)+" à "+string_date.substring(16,18)+'h'+string_date.substring(19,21);
            }

            calendarApi.addEvent({ id:sample.id,
              title: sample.data.name,
              textColor:'rgba(50, 50, 50)',
              date: date,
              color: couleur,
              description: desc
              // display: 'background'
            })
          }

          this.setState({executed: true})
        }
    }

  }
  


  render(

  ) {

    // var timeout_sample = setTimeout(this.AddEventToList(),0)
    // var timeout1 = setTimeout(this.GraphTimeout(),1000);
    // var timeout2 = setTimeout(this.GraphTimeout2(),1000);
    // var timeout3 = setTimeout(this.GraphTimeout3(),1000);


    return (
      <div id = 'home-page'>
        <div  id = 'home-content' >
          <h1> Welcome to your personnal workspace </h1>
          {/* <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
            <div style={{ width: "450px", height: "250px", marginRight: "50px"}}>
              <form name="form1" id="form1" action="/action_page.php" style={{
                width: "200px",
                height: "30px",
                marginBottom : '30px'
                }}>
                <select name="selected-time" id="selected-time">
                    <option value="months" selected="selected">3 derniers mois</option>
                    <option value="30days" selected="selected">30 derniers jours</option>
                    <option value="week" selected="selected">7 derniers jours</option>
                  </select>
              </form>
              <canvas id="myChart"></canvas> 
            </div>

            <div style={{ width: "500px", height: "250px"}}>
            <form name="form1" id="form1" action="/action_page.php" style={{
                width: "200px",
                height: "30px",
                marginBottom : '30px'
                }}>
                <select name="selected-val" id="selected-val">
                    <option value="group" selected="selected">Poids par groupes</option>
                    <option value="project" selected="selected">Poids par projets</option>
                  </select>
              </form>
              <canvas id="mySecondChart"></canvas>
            </div>
          </div> */}

          {/* <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
            <div style={{ width: "500px", height: "250px",marginRight: "50px"}}>
              <form name="form1" id="form1" action="/action_page.php" style={{
                width: "fit-content",
                height: "30px",
                marginBottom : '30px'
                }}>
                <select name="selected-time-cost" id="selected-time-cost">
                  <option value="months" selected="selected">Coût par trimestres</option>
                  <option value="dual" selected="selected">Coût par activité</option>
                  <option value="groups" selected="selected">Coût du mois par groupes</option>
                  <option value="week" selected="selected">Coût du mois détaillé</option>
                </select>
              </form>
              <canvas id="myThirdChart"></canvas>
            </div>

            <div style={{ width: "450px", height: "250px"}}>
              <div id="color-calendar" style={{ width: "450px", height: "350px", marginTop:'3OOpx' ,backgroundColor: 'white', borderRadius: '10px'}}>
                <FullCalendar
                  ref={this.calendarRef}
                  plugins={[ dayGridPlugin, listPlugin, interactionPlugin ]}

                  headerToolbar={{
                    left: 'dayGridMonth,dayGridWeek',
                    center: 'title',
                    right: 'listWeek,custom1'
                  }}
                  footerToolbar= {{
                    left: '',
                    center: 'prevYear,prev,today,next,nextYear',
                    right: ''}
                  }

                  customButtons= { {
                    custom1 : {
                      text: '?',
                      click: this.ClickOnCustom
                    }
                  }}

                  initialView="dayGridMonth"
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  
                  nowIndicator= 'true'
                  eventMaxStack= '3'
                  eventTextColor= 'black'
                  eventTimeFormat={
                    {hour: '2-digit',
                    minute: '2-digit',
                    meridiem: false}
                  }
                  dayMaxEvents= 'true'

                  height= '100%'
                  
                  events={[
                  ]}
                  eventDidMount={this.handleHover}
                  eventClick={this.handleEventClick}
                />
              </div>
            </div>
          </div> */}


          {/* <div className = 'home-row-container'> 
            <ProjectRecap projectList = {this.state.projectList}/>
            <NewsRecap />
          </div> */}

        </div>
      </div>
    );
  }
}



const ProjectRecap = (props) => (
  <div id = 'recap-container'>
    {props.projectList.map((project) => {
      return (
        <div className = 'project-button' > 
          <h3>{project}</h3> 
        </div>
      )
      })
    }
  </div>
);

const NewsRecap = (props) => (
  <div id = 'recap-container'>
    <div className = 'recap-content'>

    </div>
  </div>
);

const Calendar2 = (props) => (
  <div id = 'recap-container'>
  </div>
);

const Statistics = (props) => (
  <div id = 'recap-container'>
  </div>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(HomePage);
