import React, { Component, useTransition } from 'react';

import './initial_styles.css';
import './style_mobile_refact.css';

import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import { GoogleReCaptchaProvider, GoogleReCaptcha, useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import { withFirebase } from '../Firebase';
import Reaptcha from 'reaptcha';
import moment from 'moment'
import { MenuItem, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { styled } from '@mui/styles';
import { Select } from '@mui/material';
import { PopupWidget, PopupButton} from "react-calendly";
import backgroundImage from '../../Images/bgimg.jpg';
import platformImg from '../../Images/platformNewcolor.jpeg';
import MLImg from '../../Images/computerScienceColor.jpg';
import multimodalImg from '../../Images/multimodalDataset.jpg'

import logoBaclesse from '../../Images/logo_baclesse.png'
import logoEU from '../../Images/logo_eu.jpeg'
import logoMinistere from '../../Images/logo_ministere.png'
import logoUnicancer from '../../Images/logo_unicancer.png'
import logoNormandie from '../../Images/logo_normandie.jpg'
import logoBPI from '../../Images/logo_BPI.png'
import logoIC from '../../Images/logo_IC.jpeg'
import logoNI from '../../Images/logo_NI.png'
import logoUNI from '../../Images/logo_UNI.png'
import logoInserm from '../../Images/logo_inserm.jpeg'



const SITE_KEY = "6Lc-9mcgAAAAAH-Nnrx1GdWkkMzuR-7sLtrW5uQH";

const INITIAL_STATE = {
  email: '',
  error: null,
};



class Landing extends Component {
  constructor(props) {
    super(props);

    this.myRefs = [];
    this.Click = 0;
    this.Click_capt = 0;
    this.state = { ...INITIAL_STATE };

    this.captcha = null;
    this.state = {
      catalogHeight: "0px",
      captchaReady: false,
      verified: false,
      human: false,
      captchaResult: null,
      imageURL: "",
      value: "",

      navBarDisplayed: "hidden",
      navBarOpened: false,
      navBarWidth: "0vw",
      menuOpacity: 0,
      menuDisplayed: "hidden",
      formValidationTextDisplay: "none",
    };

    this.onSubmitFirebase = this.onSubmitFirebase.bind(this);
  }

  onSubmitFirebase(){
    this.setState({formValidationTextDisplay:"block"})
    this.props.firebase.FormLead().add({
      email: this.state.value,
      creation_date: moment().toDate(),
      })
  };

  onSubmit = event => {
    const { email } = this.state;
    event.preventDefault();

    if(this.state.human) {
      this.onSubmitFirebase();
      alert("L'adresse a été envoyée");
    }  
  };

  onSubmitTest = () => {
    const { email } = this.state;

    if(this.state.human) {
      this.onSubmitFirebase();
      alert("L'adresse a été envoyée");
    }
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  HandleNavDisplay() {
    if(!this.state.navBarOpened) {
      this.setState({navBarDisplayed: "visible", navBarWidth: "200px", navBarOpened: true, menuOpacity: 100, menuDisplayed: "visible"})} 
      else {
        this.setState({navBarWidth: "0vw", navBarOpened: false, menuOpacity: 0, menuDisplayed: "hidden"})
      }
  }

  HandleScroll(index) {
    this.setState({navBarDisplayed:"hidden",  navBarOpened: false, navBarWidth: "0vw", menuOpacity: 0, menuDisplayed: "hidden"})
    this.myRefs[index].scrollIntoView({behavior:'smooth'});
  }

  Compte_click_capt = event => {
    this.Click_capt = this.Click_capt +1;
    return(this.Click_capt)
  }

  onVerify = recaptchaResponse => {

    try{
      fetch('https://cors-anywhere.herokuapp.com/https://www.google.com/recaptcha/api/siteverify',{ 
      method: 'POST',
      headers:{
        'Accept': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded'},
      body: `secret=6LdT7mcgAAAAABpIHWHQKiBkKmUKBRyxrsUdprGc&response=${recaptchaResponse}` })
      .then((results) => results.json())
      .then((result) => {
        console.log("résultat JSON : ",result)
        console.log("succès de la requête ? ",result.success);

        if (result.success) {
          this.setState({
            human: true,
            verified: true
          });
          this.onSubmitTest();
        }

        else{
          console.log("erreur, l'utilisateur n'est pas humain");
          this.setState({
            verified: true
          });
        }
      })
    } 
    
    catch (error) { console.log(error)}
  }

  VerifyData = token => {

    try {
      fetch('https://cors-anywhere.herokuapp.com/https://www.google.com/recaptcha/api/siteverify', {
      method: 'POST',
        headers:{
          'Accept': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `secret=6Lc-9mcgAAAAAAt_seUpcnjz_V1mf_kAE6Vogyvu&response=${token}` })
        .then((results) => results.json())
        .then(res => {
          console.log("res =",res)

          this.setState({
            captchaReady: false,
            captchaResult: res
          });

          if (this.state.captchaResult.success) { //Si la requête a réussi

            if(this.state.captchaResult.score > 0.7){ //et que le score est plus grand qu'un certain seuil
              this.setState({ //Alors on peut considérer que l'utilisateur est humain
                human: true,
                verified: true,
              });
              this.onSubmitTest();
            }

            else {

              if(this.state.captchaResult.score > 0.3 && !(this.state.verified)) { 
                if(this.Click_capt < 2) { //On vérifie que le captcha n'a pas encore été rendered
                  this.captcha.renderExplicitly("test-captcha");
                }
          
                this.onVerify(); //Puis on regarde si l'utilisateur est humain ou non, avec le captcha v2
              }
              alert("Veuillez résoudre le captcha pour prouver que vous êtes bien humain");
            }
          }
          else{
            console.log("erreur, l'utilisateur n'est pas humain");
          }
        }
      );
    }
    catch (error) { console.log(error)}

  }


  handleOnClick = event => {
    event.preventDefault();
    this.setState({
      captchaReady: true,
    });
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        this.VerifyData(token);
      });
    });
  }


  showErrorInput () {
    if(!(document.emailForm.email.value != "")){
      document.getElementById("error-input-arobase").style.display="none";
      document.getElementById("error-input-none").style.display="block";
    }
    else if(!document.emailForm.email.value.includes('@')) {
      document.getElementById("error-input-none").style.display="none";
      document.getElementById("error-input-arobase").style.display="block";
    }

  }

  hideErrorInput () {
    document.getElementById("error-input-arobase").style.display="none";
    document.getElementById("error-input-none").style.display="none";
  }


  ClickCaptcha = event => {

    if( document.emailForm.email.value != "") {
      this.hideErrorInput();

      if (!document.emailForm.email.value.includes('@')) {
        this.showErrorInput();
      }
      else {
        this.Compte_click_capt();

        if(!this.state.verified) { //On teste une première fois l'utilisateur, avec le captcha v3
          this.handleOnClick(event);
        }
      }
    }
    else {
      this.showErrorInput();
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    this.setState({value: event.target.value});
  }

  render () {

    const { email, error } = this.state;
    const isInvalid =  email === '';

    return (
      <div id = 'landing-page' ref={(ref) => { this.myRefs[0] = ref }}>
        <div className="sidebar" style = {{width:this.state.navBarWidth, visibility:this.state.navBarDisplayed}}>
          <a className="closebtn" style = {{opacity : this.state.menuOpacity, visibility:this.state.menuDisplayed}} onClick= {() => this.HandleNavDisplay()}>×</a>
          <div id = "menu" style = {{opacity : this.state.menuOpacity, visibility : this.state.menuDisplayed}}>
            <a className ='navigation-button' onClick = {() => this.HandleScroll(0)} >Home</a>
            <a className ='navigation-button' onClick = {() => this.HandleScroll(1)} >Products</a>
            <a className ='navigation-button' onClick = {() => this.HandleScroll(3)} >Our partners</a>
          </div>
        </div>

        <div id = 'header'>
          <img id = 'logo-header' onClick = {() => this.myRefs[0].scrollIntoView({behavior:'smooth'})} src = {"https://hyprview-landing-images.s3.eu-west-3.amazonaws.com/logo_hyprview_thin.png"} alt = ''/>
          <button id="openbtn" onClick={() => this.HandleNavDisplay()} >☰</button>
          <ul id = 'navigation-list'>
          <li><button type="button" className = 'navigation-button' onClick = {() => this.myRefs[0].scrollIntoView({behavior:'smooth'})} >Home</button></li>
          <li><button type="button" className = 'navigation-button' onClick = {() => this.myRefs[1].scrollIntoView({behavior:'smooth'})} >Products</button></li>
          <li><button type="button" className = 'navigation-button' onClick = {() => this.myRefs[3].scrollIntoView({behavior:'smooth'})} >Our Partners</button></li>
          <li><Link to={ROUTES.SIGN_IN}><button id = 'signin-button' onClick = {() => {document.title = 'Signin'}} >Sign in</button></Link></li>

          </ul>
        </div>

        <div id = 'landing-container' style = {{backgroundImage:`url(${backgroundImage})`}}>
          <div id = 'landing-container-overlay'></div>
          <div id = 'landing-text'>
          <h1>Discover what's beyond the visible.</h1>
          <p>Embrace multimodal microscopy to navigate the hidden dimensions of biology.</p>
            <PopupButton
              url="https://calendly.com/hyprview/contact-hyprview"
              rootElement={document.getElementById("root")}
              text="Get in touch"
            />
          
          </div>
        </div>
        <div ref={(ref) => { this.myRefs[1] = ref }}> </div>

        <div className = 'technology-page'>
        <h4 className='subtitle'> Our technology</h4>
            <div className = 'technology-content'>
              <div className = 'technology-description'> 
                <h4>A groundbreaking microscopy platform transforming biomedical and pharmaceutical research.</h4>
                <p>HyprView is revolutionizing the field of biomedical and pharmaceutical research by developing a cutting-edge microscopy technology designed to enhance predictive classification models in digital pathology. </p>
                <p> Our unique platform merges various microscopy imaging modalities, significantly improving the quality of datasets used, thus elevating the accuracy of developed computational models.</p>
              </div>
              <img id = 'platform-img' src= {platformImg} />
            </div>
        </div>

        <div id = 'customer-page' style={{marginTop:'40px'}}>
          <h4 style = {{fontSize:'24px', marginBottom: '0px'}}>They trust us.</h4>
          <div id = 'customer-content' style={{height:'150px'}}>

              <img id = 'image_customer' src= {logoBaclesse} />
              <img id = 'image_customer' src= {logoInserm} />
              <img id = 'image_customer' src= {logoUnicancer} />
              <img id = 'image_customer' src= {logoUNI} />

          </div>
        </div>

        <div className = 'technology-page' style = {{paddingTop: '0px'}}>
            <div className = 'technology-content'>
              <img id = 'platform-img' src= {multimodalImg} />
              <div className = 'technology-description'> 
                <h4>By seamlessly integrating diverse imaging modalities, our technology offers a comprehensive view of samples beyond what any single method could achieve.</h4>
                <p> HyprView's multimodal approach dramatically transforms the landscape of biological sample characterization, unleashing unprecedented levels of precision and detail in research. </p> 
                <p> Our approach not only illuminates the intricate details of cellular structures but also uncovers hidden patterns and biomarkers, paving the way for breakthrough discoveries in disease diagnosis, drug development and personalized medicine.</p>
              </div>
            </div>
        </div>

        <div className = 'technology-page'>
            <p id ='quote'> “Unleashing unprecedented precision in biological sample characterization.“ </p>

            <div className = 'technology-content'>
              <div className = 'technology-description'> 
                <h4>Beyond hardware, HyprView provides tailored AI solutions for data exploitation, ensuring each laboratory's unique research and diagnostic needs are met with precision.</h4>
                <p> Our advanced AI algorithms are expertly designed to navigate the vast and intricate datasets produced by our cutting-edge multimodal microscopy platform. </p>
                <p> By intelligently analyzing and interpreting this wealth of information, we can identify patterns, anomalies, and biomarkers that are crucial for groundbreaking discoveries and diagnostics, yet may be too subtle or complex for traditional analysis methods. </p>
              </div>
              <img id = 'platform-img' src= {MLImg} />
            </div>
        </div>

        <div ref={(ref) => { this.myRefs[3] = ref }}> </div>

        <div id = 'partenaire-page'>
          <h4 className='subtitle'>Our partners</h4>
          <div id = 'partenaire-content'>
              <img id = 'image_partenaire' src= {logoNormandie} />
              <img id = 'image_partenaire' style = {{height:'50px'}} src= {logoBPI} />
              <img id = 'image_partenaire' src= {logoMinistere} />
              <img id = 'image_partenaire' src= {logoEU} />
              <img id = 'image_partenaire' src= {logoNI} />
              <img id = 'image_partenaire' src= {logoIC} />
          </div>

        </div>
        <div id = 'footer-page'>
          <div id = 'footer-container'> 
          <img onClick = {() => this.myRefs[0].scrollIntoView({behavior:'smooth'})} style = {{cursor:'pointer'}} id = 'logo-hyprview-footer' src = {"https://hyprview-landing-images.s3.eu-west-3.amazonaws.com/logo_hyprview_thin.png"}/>
            <div id = 'footer-content'>
              <div className = 'footer-column'>
                <hr/>
                <h2>LIENS</h2>
                <h4 onClick = {() => this.myRefs[0].scrollIntoView({behavior:'smooth'})} style = {{cursor:'pointer'}}>Home</h4>
                <h4 onClick = {() => this.myRefs[1].scrollIntoView({behavior:'smooth'})} style = {{cursor:'pointer'}}>Products</h4>
                <h4 onClick = {() => this.myRefs[3].scrollIntoView({behavior:'smooth'})} style = {{cursor:'pointer'}}>Partners</h4>
              </div>
              <div className = 'footer-column'>
                <hr/>
                <h2>CONTACT</h2>
                <a id="lien_mail" href="mailto: contact@hyprview.com">contact@hyprview.com</a>
                <p>17 Rue Claude Bloch <br/> 14000 Caen </p>
              </div>
            </div>
          </div> 

        </div> 
      </div>
      
    )
  };
};


export default withFirebase(Landing);
