import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyCIFXNBPJ6VR-oqBkZ-N7lohs-MPQGu10E",
  authDomain: "hyprview-app.firebaseapp.com",
  databaseURL: "https://hyprview-app-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "hyprview-app",
  storageBucket: "hyprview-app.appspot.com",
  messagingSenderId: "683051021346",
  appId: "1:683051021346:web:8ad822372480d406fac67b"
};


class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();

    // this.db = app.database();
    this.firestore = app.firestore();
    this.storage = app.storage()
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** User API ***

  // user = uid => this.db.ref(`users/${uid}`);
  // users = () => this.db.ref('users');

  Projects = () => this.firestore.collection('Projects');
  Groups = () => this.firestore.collection('Groupes');
  Samples = () => this.firestore.collection('Samples');
  Users = () => this.firestore.collection('Users');
  FormLead = () => this.firestore.collection('FormLead');
  ContactForm = () => this.firestore.collection('ContactForm');
  Organisation = () => this.firestore.collection('Organisation');
  Modalities = () => this.firestore.collection('Modalities');
  NewSampleRegistrationForm = () => this.firestore.collection('Users/' + this.auth.currentUser.uid + '/NewSampleRegistrationForms');
  Storage = () => this.storage;
}

export default Firebase;
