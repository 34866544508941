import React from 'react';

import { AuthUserContext } from '../Session';
import PasswordChangeForm from '../PasswordChange';
import { withAuthorization } from '../Session';
import './styles.css'

const SettingsPage = () => {
  
  return (
  <AuthUserContext.Consumer>
    {authUser => (
      <div id = 'settings-content'>
        <div id = 'settings-page'>
          <h4> Settings disabled</h4>
          {/* <h4>Changement de mot de passe :</h4>
          <PasswordChangeForm /> */}
          </div>
      </div>
    )}
  </AuthUserContext.Consumer>
  )
};

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(SettingsPage);
