import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import moment from 'moment'

import './styles.css'

const FormPage = () => (
  <div id = 'contact-form-content'>
    <Link to={ROUTES.LANDING}> <img src = {'https://hyprview-landing-images.s3.eu-west-3.amazonaws.com/logo_hyprview_thin_black.png'}/></Link>
    <div id = 'contact-form' >
      <ContactForm />
    </div>
  </div>
);

class ContactFormBase extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      company: "",
      message: "",
      formSubmissionTextDisplay: "none",
    }
  }

  onSubmit = event => {
    this.props.firebase.ContactForm().add({
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      company: this.state.company,
      message: this.state.message,
      creation_date: moment().toDate(),
      })
    event.preventDefault();
    this.setState({formSubmissionTextDisplay:"block",
    firstname:"",
    lastname:"",
    email:"",
    company:"",
    message:"",
    })
  }


  // onSubmit = event => {
  //   const { username, email, passwordOne } = this.state;
  //   const role = 'user'
  //   const registration_date = moment().toDate()

  //   this.props.firebase
  //     .doCreateUserWithEmailAndPassword(email, passwordOne)
  //     .then(authUser => {
  //       this.props.firebase.Users().doc(authUser.user.uid)
  //       .set({username,
  //             email,
  //             role,
  //             registration_date
  //             })
  //         .then(() => {
  //           this.setState({ ...INITIAL_STATE });
  //           this.props.history.push(ROUTES.HOME);
  //         })
  //         .catch(error => {
  //           this.setState({ error });
  //         });
  //     })
  //     .catch(error => {
  //       this.setState({ error });
  //     });

  //   event.preventDefault();
  // };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      firstname,
      lastname,
      email,
      company,
      message,
      error,
    } = this.state;

    const isInvalid =
      message === '' ||
      company === '' ||
      email === '' ||
      lastname === '';

    return (
      <form onSubmit={this.onSubmit}>
        <input
          name="firstname"
          value={firstname}
          onChange={this.onChange}
          type="text"
          placeholder="First name *"
        />
        <input
          name="lastname"
          value={lastname}
          onChange={this.onChange}
          type="text"
          placeholder="Last name *"
        />
        <input
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email *"
        />
        <input
          name="company"
          value={company}
          onChange={this.onChange}
          type="text"
          placeholder="Company name *"
        />
        <input
          style = {{height:"120px"}}
          id = 'message-input'
          name="message"
          value={message}
          onChange={this.onChange}
          type="text"
          placeholder="Your message *"
        />

        <button type="submit">
          Send request
        </button>
      
      <p id = "contact-form-submission-text" style = {{display:this.state.formSubmissionTextDisplay}}> Thank you for contacting us ! </p> <p id = "contact-form-submission-text" style = {{display:this.state.formSubmissionTextDisplay}}> We will get back to you very soon. </p>
      </form>
      
    );
  }
}

const FormLink = () => (
  <p style = {{color:'rgb(0, 0, 0)' , fontSize:'14px'}}>
    Don't have an account yet ? <Link style = {{color:'rgb(0, 0, 0)' , fontSize:'14px'}} to={ROUTES.CONTACT_FORM}>Request a free trial !</Link>
  </p>
);

const ContactForm = withRouter(withFirebase(ContactFormBase));

export default FormPage;

export { ContactForm, FormLink };
