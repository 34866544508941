import React from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@iconify/react';
import homeTwotone from '@iconify/icons-ant-design/home-twotone';
import imageIcon from '@iconify/icons-mi/image';
import deliveryIcon from '@iconify/icons-icon-park-outline/delivery';
import outlineSettings from '@iconify/icons-ic/outline-settings';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import {Home, Image, Settings, LocalPostOffice, ExpandMore} from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import './styles.css';
import { styled } from '@mui/styles';
import { withAuthorization } from '../Session';


const NavigationApp = (props) => {
  const [isSelected, setSelected] = React.useState([false, false, false, false, false]);
  const [projectsList, setProjectsList] = React.useState({});
  const [projectListSelection, setProjectListSelection] = React.useState([]);

  const handleListElementStyle = (index) => {
    if (isSelected[index]) {
      return;
    }
    let newSelected = [false, false, false, false, false];
    newSelected[index] = !isSelected[index]
    setSelected(newSelected);
    setProjectListSelection(Array(Object.keys(projectsList).length).fill(false))
  };

  const handleProjectListElementStyle = (index) => {
    if (projectListSelection[index]) {
      return;
    }
    let newSelected = Array(Object.keys(projectsList).length).fill(false);
    newSelected[index] = !newSelected[index]
    setProjectListSelection(newSelected);
    setSelected(Array(5).fill(false))
  }

  React.useEffect(() => {
    console.log(props.firebase.auth.currentUser.uid)
    props.firebase.Projects().where('owner_id', '==', props.firebase.auth.currentUser.uid).orderBy('last_update').get()
    .then((projects) => {
      var projectsList = {}
      var projectListSelectionInit = []
      projects.forEach(element => {
        console.log('here', element.data())

        projectsList[element.id] = element.data().name
        projectListSelectionInit.push(false)
      });
      setProjectsList(projectsList)
      setProjectListSelection(projectListSelectionInit)
    })
    }
    , []);

  return (
    <div id = 'navigation-app-bar'>
      <img id = 'logo-navigation-app-bar'  src = {"https://hyprview-landing-images.s3.eu-west-3.amazonaws.com/logo_hyprview_thin.png"} alt = ''/>
      <div id = 'navigation-app-list'>
        <div className='navigation-list-element-container' onClick={() => {handleListElementStyle(0); document.title = 'Home'}}
          style = { isSelected[0] ? {backgroundColor:'rgb(40,40,40)'}:{}}
        >
          <Link style = {isSelected[0] ? {cursor:'default'} : {cursor:'pointer'}} className = 'link-navigation-app-bar' to={ROUTES.HOME}> 
            <Home className = 'navigation-app-icon' />
            <p>Home</p>
          </Link>
        </div>
        <div >
          <Accordion disableGutters sx = {{width: '250px', backgroundColor: 'black'}}>
            <AccordionSummary 
              expandIcon={<ExpandMore style = {{color:'white'}} />}
              sx = {{
                '& .MuiAccordionSummary-content': {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '40px',
                  margin:'0px',
                },
                backgroundColor: 'black',
                color: 'white',
                padding: '0px',
                border: '0px',
                boxShadow: 'none',
                paddingRight: '5px',
                '&:hover': {
                  backgroundColor: 'rgb(40, 40, 40)',
                },

              }}
            >
              <Image className = 'navigation-app-icon' />
              <p style = {{ fontSize:'14px', margin:'0px', marginLeft:'10px', height:'16px'}}>Projects</p>
            </AccordionSummary>
            <AccordionDetails sx = {{padding:'0px'}}>
                {Object.keys(projectsList).map((projectId, index) => {
                  return (
                    <Link key = {projectId} style = {projectListSelection[index] ? {cursor:'default'} : {cursor:'pointer', textDecoration:'none'}} to={ROUTES.FILE_BROWSER + '/' + projectId}>
                      <div className = 'project-list-element-container' key = {projectId} style = {projectListSelection[index] ? {backgroundColor:'rgb(40,40,40)'} : {}} onClick={() => handleProjectListElementStyle(index)}>
                        <p key = {projectId} style = {{ fontSize:'14px', margin:'0px', marginLeft:'10px', color:'white'}}>{projectsList[projectId]}</p>
                      </div>
                    </Link>
                  )
                  }
                )}
            </AccordionDetails>
          </Accordion>
        </div>
        <div className='navigation-list-element-container' onClick={() => {handleListElementStyle(3); document.title = 'Sample registration'}}
          style = { isSelected[3] ? {backgroundColor:'rgb(40,40,40)'}:{}}
        >
          <Link style = {isSelected[3] ? {cursor:'default'} : {cursor:'pointer'}} className = 'link-navigation-app-bar' to={ROUTES.NEW_REGISTRATION}>
            <LocalPostOffice className = 'navigation-app-icon' />
            <p>Sample registration</p>
          </Link>
        </div>
        <div className='navigation-list-element-container' onClick={() => {handleListElementStyle(4); document.title = 'Settings'}}
          style = { isSelected[4] ? {backgroundColor:'rgb(40,40,40)'}:{}}
        >
          <Link style = {isSelected[4] ? {cursor:'default'} : {cursor:'pointer'}} className = 'link-navigation-app-bar' to={ROUTES.SETTINGS}>
            <Settings className = 'navigation-app-icon' />
            <p>Settings</p>
          </Link>
        </div>
          <div className='navigation-list-element-container' style = {{position:'absolute', bottom:'2%', width:'230px'}} onClick={() => {document.title = 'Signin'}}>
            <SignOutButton />
          </div>
      </div>
      
      <div id = 'logo-background-navigation-app-container'> 
        <img id = 'logo-background-navigation-app-bar'  src = {"https://hyprview-landing-images.s3.eu-west-3.amazonaws.com/background-logo.png"} alt = ''/>
      </div>
    </div>
  )
};
const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(NavigationApp);
